import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../common/DataTable/DataTable';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import AddEditMaterials from './AddEditMaterials';
import global from '../../../classes/global';
let _items: any = [];
let categoryItems:any = [];
let measuringUnitItems:any = [];

export default class Materials extends React.Component<any, any> {
    public currentUser: any;
    public columns: any;
    public myRef: any;
    constructor(props: any) {
        super(props);


        this._getItemData = this._getItemData.bind(this);
        this.DeleteDialog = this.DeleteDialog.bind(this);
        this.myRef = React.createRef();
        this.currentUser = this.props.currentUser;
        if (this.props.role !== global.worxOnline_Admin) {
            window.open('#/AccessDenied', '_self');
        }
        this.columns = [
            // {
            //     field: 'id',
            //     title: 'ID',
            //     // width: 160,
            //     type: 'string',
            //     allowHiding: false,
            // },
            {
                field: 'resourceCode',
                title: 'Resource Code',
                // width: 160,
                type: 'string',
                allowHiding: false,
            },
            {
                field: 'title',
                title: 'Title',
                // width: 160,
                type: 'string',
                allowHiding: false,
                sortOrder: 'asc'
            },
            {
                field: 'category',
                title: 'Category',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'measuringUnit',
                title: 'Unit Of Measure',
                // width: 160,
                type: 'string',
                allowHiding: true
            },
            {
                field: 'active',
                title: 'Is Active',
                // width: 160,
                type: 'boolean',
                allowHiding: true
            }
        ];
        this.state = {
            items: _items,
            columns: this.columns
        };
    }

    public componentDidMount() {
        this._getItemData();
    }

    public DeleteItem(item: any) {
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' type={'converging-spinner'} /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true,
            showDeleteAlert: false,
        });
        let dataToPassToService: any = {};
        dataToPassToService.id = item;
        // dataToPassToService.isActive = false;
        // dataToPassToService.updated = new Date();
        // dataToPassToService.updatedBy = this.props.currentUser.Email;
        // dataToPassToService.created = this.state.created;
        utility.genericDeleteAPICall(Config.materialsListName, item).then((data: any) => {
            this.setState({
                alertMessage: 'Materials has been deleted successfully.',
                showAlert: true,
                showDeleteAlert: false,
                hideAlertButton: false,
                loading: false
            });
            this._getItemData();
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'Something went wrong!',
                hideAlertButton: false,
                showAlert: false
            });
        });

    }

    // edit activty modal

    private EditItemModal(item: any) {
        //console.log("item", item);
        this.setState({ showModal: true, details: item, mode: 'edit' });
    }
    private _showModal = (): void => {
        this.setState({ showModal: true, details: null, mode: 'add' });
    }
    public renderNewModal() {
        return (
            <AddEditMaterials
                showModal={this.state.showModal}
                onClose={() => this._closeModal()}
                onClick={() => this._getItemData()}
                message={(e: any) => this.message(e)}
                currentUser={this.currentUser}
                details={this.state.details}
                role={this.props.role}
                mode={this.state.mode}
                categoryItems={this.state.categoryItems}
                measuringUnitItems={this.state.measuringUnitItems}
            />
        );
    }
    private _closeModal = (): void => {
        this.setState({ showModal: false });
    }
    public message(message: any) {
        //console.log(message);
        this.setState({
            alertMessage: message + ' successfully',
            showAlert: true
        });
    }

    private async _getItemData() {
        let dataToPassToService: any = {};
        this.setState({
            loading: true
        });
        // console.log("dataToPassToService", dataToPassToService);
        try{
            const data:any = await utility.genericGetAPICallForList(Config.materialsListName);
            _items = [];
            const uniqueCategories = new Set();
            const uniqueMeasuringUnit = new Set();
            //console.log(data);

             _items = data.map((r: any) => ({
                id: r.id,
                resourceCode: r.resourceCode,
                title: r.title,
                measuringUnit: r.measuringUnit,
                category: r.category,
                active: r.isActive ? true : false,
                updatedBy: r.updatedBy,
                updatedOn: r.updated,
                createdBy: r.createdBy,
                createdOn: r.created,
            }));

            _items = utility._sortItems(_items, 'title', false);

            const categoryItems = [...new Set(_items.map((item: { category: any; }) => item.category))].filter(Boolean);
            const measuringUnitItems = [...new Set(_items.map((item: { measuringUnit: any; }) => item.measuringUnit))].filter(Boolean);
            
            const sortedCategoryItems = utility._sortItems(categoryItems, 'text', false);
            const sortedMeasuringUnitItems = utility._sortItems(measuringUnitItems, 'text', false);
            
            this.setState({
                items: _items,
                callAfterInit: true,
                loading: false,
                preItems: _items,
                categoryItems: sortedCategoryItems,
                measuringUnitItems: sortedMeasuringUnitItems,
            });
        } catch (err) {
            console.log(err);
            this.setState({
                loading: false,
                callAfterInit: true,
            });
        }
    }


    public setSelection = (id: any) => {
        this.setState({
            selectedRow: id
        });
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }



    public render(): React.ReactElement<any> {
        const filterValue = [['active', '=', true]];
        return (
            <React.Fragment>
                {this.renderNewModal()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                {this.state.showAlert && this.alert()}
                <div className={"SearchDiv"}>
                    <div className={'headingDiv'}>
                        <span className={"heading"}> Materials </span>
                    </div>
                </div>
                <div>
                    {!this.state.loading ?
                        <div ref={this.myRef}>
                            <DataTable
                                style={{ width: '100%' }}
                                filteralbe={true}
                                groupable={true}
                                rows={this.state.items}
                                columns={this.state.columns}
                                fileName={'Materials'}
                                columnChooser={'usersColumns'}
                                selectionMode={false}
                                showHideColumnMenu={true}
                                callAfterInit={this.state.callAfterInit}
                                stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                loading={this.state.loading}
                                allowExportSelectedData={false}
                                showNewModal={() => this._showModal()}
                                deleteItem={(a: any) => this.DeleteDialog(a)}
                                editItem={(a: any) => this.EditItemModal(a)}
                                // viewFile={(e)=>this.viewFile(e)}
                                showCommandBtn={true}
                                hideCommandDeleteBtn={false}
                                showNewBtn={true}
                                showViewDetailBtn={false}
                                hideNewBtn={this.state.showModal}
                                // hideNewBtn={true}
                                defaultfiltervalue={filterValue}
                            />
                        </div>
                        :
                        <Spinner size='large' type={'converging-spinner'} className="loader" />
                    }

                </div>
            </React.Fragment>
        );
    }
    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                hideAlertButton={this.state.hideAlertButton}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }
    public DeleteDialog(item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            deleteItem: item
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.DeleteItem(this.state.deleteItem)}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }
}
