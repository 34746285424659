import * as React from 'react';
import ArrayStore from 'devextreme/data/array_store';
import List from 'devextreme-react/list';
import SchedulerCard from './SchedulerCard';


export default class ListView extends React.Component<any, any>{
    public column: any;
    constructor(props: any) {
        super(props);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.state = {
            group: 'worktype',
        };
    }

    public componentDidMount() {

    }
    public handleGroupChange = (value: any) => {

        this.setState({
            group: value
        })
    }
    public render() {
        const dataSourceOptions = {
            store: new ArrayStore({
                data: this.props.items,
                key: 'id',
            }),
            group: !this.props.mapView ? this.state.group : 'worktype',
            searchExpr: ['worktype', 'Workordernumber', 'id', 'text', 'fieldstatus', 'crew', 'priority', 'location'],
        };
        return (
            <div className={'p-10'}>
                {!this.props.mapView && (
                    <>
                        <div className="displayFlex">
                            <div className={`schedulerGroup borderFirst ${this.state.group === 'worktype' ? 'selectedGroup' : ''}`} onClick={() => this.handleGroupChange('worktype')}>Activity</div>
                            <div className={`schedulerGroup  ${this.state.group === 'priority' ? 'selectedGroup' : ''}`} onClick={() => this.handleGroupChange('priority')}>Priority</div>
                            <div className={`schedulerGroup borderLast  ${this.state.group === 'location' ? 'selectedGroup' : ''}`} onClick={() => this.handleGroupChange('location')}>Location</div>
                        </div>
                        <br />
                    </>
                )}
                <List
                    className='listView'
                    selectionMode="single"
                    dataSource={dataSourceOptions}
                    grouped={true}
                    searchEnabled={this.props.mapView ? false : true}
                    selectedItemKeys={this.state.selectedItemKeys}
                    // onSelectionChanged={this.handleListSelectionChange}
                    itemRender={(e: any) => this.renderListItem(e, this.props)}
                    groupRender={this.renderListGroup}
                    elementAttr={{ class: 'list' }}
                    collapsibleGroups={true}
                />
            </div>
        );
    }


    public renderListGroup(group: any) {
        return <div className="city">{group.key}</div>;
    }

    public renderListItem(woData: any, props: any) {

        return (
            <SchedulerCard
                woData={woData}
                view={props.view}
                viewItem={(e: any) => props.viewItem(e)}
                viewWO={(e: any) => props.viewWO(e)}
                zoomToSelectWO={(e: any) => props.zoomToSelectWO(e)}
                draggingGroupName={props.draggingGroupName}
                onItemDragStart={(e: any) => props.onItemDragStart(e)}
                onItemDragEnd={(e: any) => props.onItemDragEnd(e)}
                mapView={props.mapView}
                draggingAllowed={true}
                schedulerlistDraggingAllowed={props.schedulerlistDraggingAllowed}
                onDragStart={(e: any, woData: any) => props.onDragStart(e, woData)}
                dashBoardCard={props.dashBoardCard}
                _onShowNotesPanel={() => props._onShowNotesPanel(woData)}
                onUnassignedSelection={(e: any) => props.onUnassignedSelection(e)}
                onUnassignedSelectionOption={props.onUnassignedSelectionOption}
                showCrew={props.showCrew}
            />
        );
    }
}