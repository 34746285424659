import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import '../../css/Workorder.css';
import TextBoxInput from '../common/reusableComponents/TextBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import DateBoxInput from '../common/reusableComponents/DateBox';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import global from '../../classes/global';
import MultiSelectTreeView from '../common/reusableComponents/MultiSelectTreeView';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
import moment from 'moment-timezone';
// import IndicatorIcon from '../common/indicatorIcon';
// import { Template } from 'devextreme-react/core/template';
let _items: any = [];

export default class SearchWorkRequest extends React.Component<any, any> {
    public myRef: any;
    public setupDataPromise: any = [];
    public users: any;
    public columns: any;
    constructor(props: any) {
        super(props);
        this.setupDataPromise = [];
        this.users = [];
        this.myRef = React.createRef();
        this.getWR = this.getWR.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.viewDetails = this.viewDetails.bind(this);
        this.columns =
            [
                {
                    field: 'id',
                    title: 'WO Work Request #',
                    // width: 160,
                    type: 'string',
                    allowHiding: false,
                    link: 'requestNumberLink',
                },
                {
                    field: 'erpWorkRequestNumber',
                    title: 'Assetic Req #',
                    // width: 160,
                    type: 'string',
                    allowHiding: false
                },
                {
                    field: 'workRequestDescription',
                    title: 'Description',
                    // width: 160,
                    type: 'string',
                    allowHiding: false
                },
                {
                    field: 'workrequestTypeDesc',
                    title: 'Request Type',
                    // width: 160,
                    type: 'string',
                    allowHiding: true,
                },
                {
                    field: 'workrequestStatus',
                    title: 'Status',
                    width: 160,
                    type: 'string',
                    allowHiding: true
                },
                {
                    field: 'created',
                    title: 'Created',
                    // width: 280,
                    type: 'datetime',
                    allowHiding: false,
                },
                {
                    field: 'created',
                    title: 'Resolution Due Date',
                    // width: 280,
                    type: 'datetime',
                    allowHiding: false,
                },

                {
                    field: 'location',
                    title: 'Location',
                    width: 160,
                    type: 'string',
                    allowHiding: true
                },
                {
                    field: 'referenceNumber',
                    title: 'Reference Number',
                    width: 160,
                    type: 'string',
                    visibleColumn: false,
                    allowHiding: true
                },

                {
                    field: 'inspectedBy',
                    title: 'Reactive Inspector',
                    // width: 160,
                    type: 'string',
                    allowHiding: true
                }
            ];

        this.state = {
            selectedTab: 0,
            showAllWR: false,
            disabled: true,
            totalResults: 100,
            items: _items,
            isLoaded: false,
            StatusItems: '',
            priorityItems: '',
            requesttypeItems: '',
            columns: this.columns,
            isRBLoaded: false,
            isRTLoaded: false
        };
    }

    public componentDidCache = () => {
        ////console.log('view cached. Scroll position = ' + document.getElementById("s4-workspace").scrollTop);
        //scrollPosition = document.getElementById("s4-workspace").scrollTop;
    }

    public componentDidRecover = () => {
    }


    public async componentDidMount() {
        this.setState({ loadingFields: true });
        await Promise.all([
            this.getWRType(),
            this.getStatuses(),
            this.getUsers(),
            this._getPrioritiesData()
        ]);
        if (this.props.selectMode) {
            this.setState({ items: [] });
        }
        else {
            let tempstate = localStorage.getItem('WRState');
            if (tempstate) {
                let allState = JSON.parse(tempstate);
                console.log({ allState })
                this.setState({
                    requestNo: allState.requestNo ? allState.requestNo : '',
                    requestorType: allState.requestorType ? allState.requestorType : '',
                    priority: allState.priority ? allState.priority : '',
                    requestStatus: allState.requestStatus ? this.getMultiselectProps(allState.requestStatus, 'StatusItems') : '',
                    requestType: allState.requestType ? this.getMultiselectProps(allState.requestType, 'requesttypeItems') : '',
                    reportfrom: allState.reportfrom ? allState.reportfrom : null,
                    reportTo: allState.reportTo ? allState.reportTo : null,
                    loadingFields: false
                })
            }
        }

        this.setState({
            resultCount: global.defaultResultCount
        });

    }
    private async _getPrioritiesData() {
        let apiName = `${Config.PriorityListName}?showAll=true`;
        try {
            const data: any = await utility.genericGetAPICall(apiName);
            const priorityItems = data
                .filter((r: any) => r.type === 'WorkRequest' && r.isActive)
                .map((r: any) => ({ id: r.id, text: r.title }));
            const sortedItems = utility._sortItems(priorityItems, 'text', false);
            this.setState({ priorityItems: sortedItems });

        } catch (err) {
            console.log(err);
        }
    }
    private handleValueChange(newValue: any, fieldName: string): void {
        this.setState({ [fieldName]: newValue.value });
    }
    public _handleKeyDown = (e: any) => {
        if (e.event.originalEvent.key === 'Enter') {
            this.getWR();
        }
    }

    public getWR(action?: any) {
        this.setState({
            items: ''
        });
        let criteriaSelected = false;
        let dataToPassToService: any = {};
        let requestType: any = [];
        if (this.state.requestType && this.state.requestType.length > 0) {
            this.state.requestType.forEach((element: any) => {
                requestType.push(element);
            });
        }
        dataToPassToService.showAll = this.state.showAllWR;
        let requestStatus: any = [];
        if (this.state.requestStatus && this.state.requestStatus.length > 0) {
            this.state.requestStatus.forEach((element: any) => {
                requestStatus.push(element);
            });
        }
        if (this.state.requestNo) {
            dataToPassToService.workRequestId = this.state.requestNo;
            criteriaSelected = true;
        }
        if (this.state.responsibleofficer) {
            dataToPassToService.createdBy = this.state.responsibleofficer.email
            criteriaSelected = true;
        }
        if (this.state.requestStatus && this.state.requestStatus.length > 0) {
            dataToPassToService.workRequestStatusIds = this.state.requestStatus;
            criteriaSelected = true;
        }

        if (this.state.requestType && this.state.requestType.length > 0) {
            dataToPassToService.workRequestTypeIds = requestType;
            criteriaSelected = true;
        }
        if (this.state.priority && this.state.priority.id) {
            dataToPassToService.priorityIds = [this.state.priority.id];
            criteriaSelected = true;
        }
        if (this.state.reportfrom && this.state.reportTo) {
            let reportfrom = new Date(this.state.reportfrom);
            let reportTo = new Date(this.state.reportTo);
            if (reportTo.getTime() < reportfrom.getTime()) {
                this.setState({
                    alertMessage: 'Reported Date To should be greater than Reported Date From',
                    showAlert: true
                });
                return;
            }
        }
        if (this.state.reportfrom || this.state.reportTo) {
            if (this.state.reportfrom) {
                const startOfDay = moment(this.state.reportfrom).startOf('day');
                const startOfDayUTC = startOfDay.utc();
                dataToPassToService.dateReportedFrom = startOfDayUTC.toISOString();
              }
              if (this.state.reportTo) {
                const endOfDay = moment(this.state.reportTo).endOf('day');
                const endOfDayUTC = endOfDay.utc();
                dataToPassToService.dateReportedTo = endOfDayUTC.toISOString();
              }
            criteriaSelected = true;
        }
        if (!criteriaSelected) {
            this.setState({
                alertMessage: 'Please select search criteria',
                showAlert: true
            });
            return;
        }
        let pageNumber = 1;
        let pageSize = this.state.totalResults ? this.state.totalResults : 200;
        let searchWOAPi = `${Config.WRlistname}?pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllWR}`
        utility.genericPostAPIcall(searchWOAPi, dataToPassToService).then((data: any) => {
            _items = [];
            if (data.length === 0 || data === null) {

                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });

                this.setState({ items: _items, loading: false });
                return;
            }
            else {
                data.forEach((item: any) => {
                    _items.push({
                        id: item.id,
                        erpWorkRequestNumber: item.erpWorkRequestNumber ? item.erpWorkRequestNumber : '',
                        workrequestTypeDesc: item.workRequestTypeTitle ? item.workRequestTypeTitle : '',
                        suburb: item.suburb ? item.suburb : '',
                        workrequestStatus: item.workRequestStatusTitle ? item.workRequestStatusTitle : '',
                        priorityId: item.priorityId ? item.priorityId : '',
                        referenceNumber: item.referenceNumber ? item.referenceNumber : '',
                        priorityDescription: item.priorityDescription ? item.priorityDescription : '',
                        workrequestStatusId: item.workRequestStatusId ? item.workRequestStatusId : '',
                        workrequestTypeId: item.workRequestTypeId ? item.workRequestTypeId : '',
                        location: item.location ? item.location : '',
                        responsibilityDescription: item.responsibilityDescription ? item.responsibilityDescription : '',
                        initiatedDateTime: item.initiatedDateTime ? item.initiatedDateTime : '',
                        workRequestDescription: item.workRequestDescription,
                        created: item.created ? item.created : '',
                        inspectedBy: item.inspectedBy ? item.inspectedBy : '',
                        resolutionDueDate: item.resolutionDueDate,
                        inspectedDateTime: item.inspectedDateTime ? item.inspectedDateTime : ''
                    });
                });

            }

            if (_items.length <= 0) {
                this.setState({
                    alertMessage: 'No results found for this criteria',
                    showAlert: true
                });
            }
            if (_items.length === 1 && !this.props.selectMode) {
                window.open(`#/WRDetail?ReqId=${_items[0].id}`, '_self')
            }
            _items = utility._sortItems(_items, 'created', true);
            this.setState({ items: _items, loading: false });
            var stateData = { ...this.state };
            localStorage.setItem('WRState', JSON.stringify(stateData));


        });
    }
    public async getWRType() {
        this.setState({ isRTLoaded: false });
        try {
            const data: any = await utility.genericGetAPICall(`${Config.WRTlistname}?showAll=true`);
            const requesttypeItems = data.map((r: any) => ({ id: r.id, text: r.title, 'Select All': (r.title) }));
            const sortedItems = utility._sortItems(requesttypeItems, 'text', false);
            this.setState({ requesttypeItems: sortedItems, isRTLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }
    public async getStatuses() {
        this.setState({ isLoaded: false });
        try {
            const data: any = await utility.genericGetAPICall(`${Config.WRstatuslist}?showAll=true`);
            const StatusItems = data.filter((r: any) => r.isActive).map((r: any) => ({
                id: r.id,
                text: r.title,
                'Select All': (r.title)
            }));
            const sortedItems = utility._sortItems(StatusItems, 'text', false);

            this.setState({
                StatusItems: sortedItems, isLoaded: true
            });
        } catch (err) {
            console.log(err);
        }
    }
    public viewDetails(item: any) {

        this.setState({
            details: item,
            showModal: true
        });
    }
    public getMultiselectProps(data: any, listData: any) {
        let returnData: Array<any> = [];
        if (this.state[listData] && data) {
            data.forEach((item: any) => {
                let tempItem = this.state[listData].find((l: any) => l.id === (item.id ? item.id : item));
                returnData.push(tempItem);
            })
        }
        return returnData;
    }

    public async getUsers() {
        this.users = [];
        this.setState({ isRBLoaded: false });
        try {
            const data: any = await utility.genericGetAPICallForList(Config.employeelistname, 'GET');
            this.users = data.map((r: any) => ({
                id: r.id,
                text: r.fullName,
                email: r.email
            }));

            this.users.sort((a: any, b: any) => a.text.localeCompare(b.text));
            this.setState({
                users: this.users, isRBLoaded: true
            });
        } catch (err) {
            console.log(err);
        }
    }


    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    private _multiFieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event });
    }
    public clearFilter() {
        this.setState({
            items: [],
            requestNo: '',
            requestType: '',
            requestStatus: '',
            reportfrom: null,
            reportTo: null,
            requestorType: '',
            actioningofficer: '',
            responsibleofficer: '',
            priority: '',
            receivingofficer: '',
            more: false,
            totalResults: 100,
            showAllWR: false,
            less: false
        });
        localStorage.setItem('WRState', '');
        let datagrid = localStorage.getItem('datagridFilterValue');
        if (datagrid) {
            localStorage.removeItem('datagridFilterValue');
        }
    }
    private stopCommsAfterInit = (): void => {
        this.setState({ callAfterInit: false });
    }
    // statusrenderLoadIndicator() {
    //     return <IndicatorIcon isLoaded={this.state.isLoaded} />;
    // }
    // requestedByLoadIndicator() {
    //     return <IndicatorIcon isLoaded={this.state.isRBLoaded} />;
    // }
    // RTLoadIndicator() {
    //     return <IndicatorIcon isLoaded={this.state.isRTLoaded} />;
    // }

    public render(): React.ReactElement<any> {
        return (
            <div >
                {this.state.showAlert && this.alert()}
                < div className={"SearchDiv"} >
                    <div className={'headingDiv'}>
                        <span className={"heading"}>Search Work Request</span>
                    </div>
                </div >
                <div className={"searchFieldDiv"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextBoxInput
                                stylingMode='underlined'
                                label="Request #"
                                value={this.state.requestNo}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'requestNo')}
                                onKeyDown={this._handleKeyDown}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <MultiSelectTreeView
                                items={this.state.requesttypeItems}
                                onChange={(e: any) => this._multiFieldChanged(e, 'requestType')}
                                selectedItem={this.state.requestType}
                                label="Request Type"
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                placeholder="Select a value..."
                            />
                             
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <MultiSelectTreeView
                                items={this.state.StatusItems}
                                onChange={(e: any) => this._multiFieldChanged(e, 'requestStatus')}
                                selectedItem={this.state.requestStatus}
                                label="Request Status"
                                stylingMode='underlined'
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                placeholder="Select a value..."
                            />
                               
                        </Grid>
                        <Grid item xs={12} sm={4} className={''}>
                            <SelectBoxInput
                                items={this.state.priorityItems}
                                stylingMode='underlined'
                                label="Priority"
                                selectedItem={this.state.priority}
                                displayExpr="text"
                                valueExpr="id"
                                showClearButton={true}
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'priority')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={''}>
                            <SelectBoxInput
                                items={this.state.users}
                                stylingMode='underlined'
                                label="Requested By"
                                selectedItem={this.state.responsibleofficer}
                                displayExpr="text"
                                showClearButton={true}
                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'responsibleofficer')}
                            />
                             
                        </Grid>
                        <Grid item xs={12} sm={4} className={''}>
                            <DateBoxInput
                                stylingMode='underlined'
                                label="Created From"
                                value={this.state.reportfrom ? new Date(this.state.reportfrom) : null}
                                type="datetime"
                                displayFormat={"dd/MM/yy hh:mm a"}
                                showClearButton={true}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'reportfrom')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={''}>
                            <DateBoxInput
                                stylingMode='underlined'
                                label="Created To"
                                value={this.state.reportTo ? new Date(this.state.reportTo) : null}
                                type="datetime"
                                displayFormat={"dd/MM/yy hh:mm a"}
                                showClearButton={true}
                                onValueChanged={(e: any) => this.handleValueChange(e, 'reportTo')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <div className={"searchButtonDiv displayFlex"}>
                                {this.state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {this.state.totalRecords}</div>}
                                <div>
                                    <NumberBoxInput
                                        stylingMode='underlined'
                                        label="Total Results to Display"
                                        min={1}
                                        max={100000000}
                                        value={this.state.totalResults}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'totalResults')}
                                    />
                                </div>
                                <div className='mt-10'>
                                    <Button variant='outlined' onClick={this.clearFilter} className={'button'}>
                                        Clear
                                    </Button>
                                    <Button onClick={this.getWR} variant='outlined' color="primary" className={'button'}>
                                        Search
                                    </Button>
                                    <CheckBoxInput value={this.state.showAllWR} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllWR')} text={'Show All Work Requests'} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>

                    {!this.state.loading ?
                        <>
                            {(this.state.items && this.state.items.length > 0) && (
                                <DataTable
                                    style={{ width: '100%' }}
                                    filteralbe={true}
                                    groupable={true}
                                    rows={this.state.items}
                                    columns={this.state.columns}
                                    fileName={'Work Request'}
                                    columnChooser={'servicerequestColumns'}
                                    selectionMode={false}
                                    showHideColumnMenu={true}
                                    callAfterInit={this.state.callAfterInit}
                                    stopCommsAfterInit={() => this.stopCommsAfterInit()}
                                    loading={this.state.loading}
                                    allowExportSelectedData={false}
                                    showNewBtn={false}
                                    showCommandBtn={false}
                                    showViewDetailBtn={true}
                                    hideNewBtn={this.state.showModal || this.state.showEditModal || this.props.hideNewBtn}
                                />
                            )}
                        </>
                        :
                        <LoadIndicator id="large-indicator" height={60} width={60} className="loader" />
                    }
                </div>


            </div >
        );
    }

    public alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
            />
        );
    }


}

