import * as React from 'react';
import SelectBox from 'devextreme-react/select-box';
interface Props {
    label?: string;
    stylingMode: string | any;
    placeholder?: string | any;
    displayExpr: string | any;
    valueExpr?: string | any
    grouped?: Boolean | any;
    selectedItem: any;
    showClearButton?: Boolean | any;
    readOnly?: Boolean | any;
    disabled?: Boolean | any;
    onSelectionChanged: any;
    required?: any;
    items: any;
    spellcheck?: Boolean | any;
}
export default class SelectBoxInput extends React.Component<Props, any> {

    constructor(props: Props) {
        super(props);

        this.state = {
            items: this.props.items,
            loading: true,
            // selectedItem:this.props.selectedItem
        }

    }
    public afterInit() {
        this.setState({
            items: this.props.items
        });
    }
    public afterInitValue() {
        // console.log(this.props.label, this.props.selectedItem)
        if (this.props.items && this.props.items.length > 0 && this.props.selectedItem) {
            let index = this.props.items.findIndex((obj: any) => obj.id === this.props.selectedItem.id);
            if(index !== -1){
                this.setState({
                    selectedItem: this.props.items[index]
                });
            }
        } else {
            this.setState({
                selectedItem: this.props.selectedItem
            });
        }
    }
    public render(): React.ReactElement<any> {
        if ((this.props.items && !this.state.items) || ((this.props.items && this.props.items.length) !== (this.state.items && this.state.items.length))) {
            this.afterInit();
        }
        if ((this.props.selectedItem && !this.state.selectedItem) || ((this.props.selectedItem && this.props.selectedItem.id) !== (this.state.selectedItem && this.state.selectedItem.id))) {
            this.afterInitValue();
        }

        return (
            <SelectBox
                dataSource={this.state.items}
                stylingMode={this.props.stylingMode}
                placeholder={this.props.placeholder}
                // selectedItem={this.state.selectedItem}
                value={this.state.selectedItem}
                // defaultValue={this.state.selectedItem?this.state.selectedItem.id:''}
                // onValueChanged={(e:any) => this.props.onValueChanged(e)}
                onSelectionChanged={(e: any) => this.props.onSelectionChanged(e)}
                showClearButton={this.props.showClearButton}
                displayExpr={this.props.displayExpr}
                // valueExpr={this.props.valueExpr}
                searchEnabled={true}
                searchMode={'contains'}
                searchExpr={"text"}
                label={this.props.label}
                labelMode={'floating'}
                grouped={this.props.grouped}
                readOnly={this.props.readOnly}
                disabled={this.props.disabled}

            >
                {/* {this.props.required && (
                    <Validator />
                )} */}
            </SelectBox>
        );
    }
}


