import * as React from "react";
import utility from "../../../classes/utility";
import { Config } from "../../../classes/config";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AlertDialog from "../../common/alertDialog";
import AssignDatatable from "../../common/DataTable/AssignDatatable";
import Spinner from "../../common/reusableComponents/Spinner";
import DateBoxInput from "../../common/reusableComponents/DateBox";
import SelectBoxInput from "../../common/reusableComponents/SelectBoxUpdated";
import NumberBoxInput from "../../common/reusableComponents/NumberBox";
import CheckBoxInput from "../../common/reusableComponents/CheckBox";
import global from "../../../classes/global";
import moment from 'moment-timezone';

let _items: any = [];

export default class WeeklyTimesheet extends React.Component<any, any> {
  public employeeNameItems: any = [];
  public departmentItems: any = [];
  public teamItems: any = [];
  public crewItems: any = [];
  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);
    this.employeeNameItems = [];
    this.departmentItems = [];
    this.teamItems = [];
    this.crewItems = [];
    this.getWeeklyTimesheet = this.getWeeklyTimesheet.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open("#/AccessDenied", "_self");
    }
    this.columns = [
      {
        field: "ledger",
        title: "Ledger",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "Wonumber",
        title: 'WorxOnline Work Order #',
        // width: 160,
        type: "string",
        allowHiding: false,
        link: 'wolink'
      },

      {
        field: "accountNumber",
        title: "Account Number",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "accountNumberDescription",
        title: "Account Number Description",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "amount",
        title: "Amount",
        // width: 160,
        type: "number",
        allowHiding: false,
      },
      {
        field: "narrative",
        title: "Narrative",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "narrative2",
        title: "Narrative 2",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "location",
        title: "Location",
        // width: 160,
        type: "string",
        allowHiding: false,
      },
      {
        field: "resourceName",
        title: "Resorce Name",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "resourceCode",
        title: "Resource Code",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "departmentName",
        title: "Department Name",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "departmentCode",
        title: "Department Code",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "teamName",
        title: "Team Name",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "teamCode",
        title: "Team Code",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "crewName",
        title: "Crew Name",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
      {
        field: "crewCode",
        title: "Crew Code",
        // width: 160,
        type: "string",
        allowHiding: true,
        visibleColumn: false
      },
    ];

    let showHideColumns = localStorage.getItem("TimesheetExportColumns");
    let tempColumns;
    if (showHideColumns != null) {
      tempColumns = JSON.parse(showHideColumns);
    } else {
      tempColumns = this.columns;
      localStorage.setItem("TimesheetExportColumns", JSON.stringify(this.columns));
    }

    this.state = {
      items: _items,
      columns: tempColumns,
      callAfterInit: false,
      loading: false,
      department:
        this.props.role === global.worxOnline_Contractor ||
          this.props.role === global.worxOnline_ContractorAdmin
          ? this.props.Department
          : "",
      disabledTeam: true,
      disabledCrew: true,
      totalResults: 100,
      showAllData: false,
      fileName: '',
    };
  }
  public async componentDidMount() {
    let tempstate = localStorage.getItem("ledgerState");
    await Promise.all([
      this._getEmployeeData(),
      this._getDepartmentData(),
      this._getTeamDataAsync(),
      this._getCrewDataAsync(),
    ]);

    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        createdFrom: allState.createdFrom
          ? allState.createdFrom
          : "",
        createdTo: allState.createdTo ? allState.createdTo : "",
        employeeName: allState.employeeName ? allState.employeeName : "",
        crew: allState.crew ? allState.crew : "",
        department: allState.department ? allState.department : "",
        teams: allState.teams ? allState.teams : "",
        fileName: allState.fileName ? allState.fileName : "",
      });
    }
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: "", showAlert: false })}
      />
    );
  }

  public getWeeklyTimesheet() {
    this.setState({
      callAfterInit: true,
      loading: true
    });
    let dataToPassToService: any = {};

    if (this.state.createdTo && this.state.createdFrom) {
      let dcreatedfrom = moment(this.state.createdFrom);
      let dcreatedTo = moment(this.state.createdTo);
      if (dcreatedTo.isBefore(dcreatedfrom, 'day')) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true,
          loading: false
        });
        return;
      }
    }

    if (this.state.createdFrom || this.state.createdTo) {
      if (this.state.createdFrom) {
        const startOfDay = moment(this.state.createdFrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.dateFrom = startOfDayUTC.toISOString();
      }
      if (this.state.createdTo) {
        const endOfDay = moment(this.state.createdTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.dateTo = endOfDayUTC.toISOString();
      }
    }

    if (this.state.employeeName) {
      dataToPassToService.resourceId = this.state.employeeName.id;
      dataToPassToService.resourceCode = this.state.employeeName.resourceCode;
    }
    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
      dataToPassToService.departmentCode = this.state.department.code;
    }
    if (this.state.teams) {
      dataToPassToService.teamId = this.state.teams.id;
      dataToPassToService.teamCode = this.state.teams.code;
    }
    if (this.state.crew) {
      dataToPassToService.crewId = this.state.crew.id;
      dataToPassToService.crewCode = this.state.crew.code;
    }
    _items = [];
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 100;
    let FinancialLedgerAPI = `${Config.FinancialLedgerList}?pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllData}`;
    utility.genericPostAPIcall(FinancialLedgerAPI, dataToPassToService).then((data: any) => {
      if (data.length === null || data.length <= 0) {

        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });

        this.setState({ items: _items, loading: false });
        return;
      } else {
        _items = data.map((item: any) => {
          return {
            ledger: item.ledger,
            accountNumber: item.accountNumber,
            accountNumberDescription: item.accountNumberDescription,
            amount: item.amount,
            narrative: item.narrative,
            narrative2: item.narrative2,
            location: item.location,
            resourceName: item.resourceName,
            resourceCode: item.resourceCode,
            departmentName: item.departmentName,
            departmentCode: item.departmentCode,
            Wonumber: item.workorderId,
            teamName: item.teamName,
            teamCode: item.teamCode,
            crewName: item.crewName,
            crewCode: item.crewCode,
            updated: item.updated,
          };
        });

        this.generateFileNameForExport();
      }
      _items = utility._sortItems(_items, 'updated', true);
      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      localStorage.setItem('ledgerState', JSON.stringify(stateData));
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 as getMonth returns zero-based month
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  private generateFileNameForExport = () => {
    let filename = '';
    const separator = '_';

    if (this.state.createdFrom) {
      filename += `From${this.formatDate(new Date(this.state.createdFrom))}${separator}`;
    }
    if (this.state.createdTo) {
      filename += `To${this.formatDate(new Date(this.state.createdTo))}${separator}`;
    }
    if (this.state.department) {
      filename += `${this.state.department.text ? this.state.department.text.replace(/\s+/g, '') : ''}${separator}`;
    }
    if (this.state.teams) {
      filename += `${this.state.teams.text ? this.state.teams.text.replace(/\s+/g, '') : ''}${separator}`;
    }
    if (this.state.crew) {
      filename += `${this.state.crew.text ? this.state.crew.text.replace(/\s+/g, '') : ''}${separator}`;
    }
    if (this.state.employeeName) {
      filename += `${this.state.employeeName.text ? this.state.employeeName.text.replace(/\s+/g, '') : ''}${separator}`;
    }

    // Remove the last separator if it exists
    if (filename.endsWith(separator)) {
      filename = filename.slice(0, -separator.length);
    }

    // Set default filename if none of the fields are chosen
    if (filename === '') {
      filename = 'TimesheetExport';
    }

    this.setState({ fileName: filename });
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === "Enter") {
      this.getWeeklyTimesheet();
    }
  };

  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id,
    });
  };

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  };

  public clearFilter() {
    this.setState({
      created: "",
      employeeName: "",
      createdFrom: "",
      createdTo: "",
      crew: "",
      department: "",
      teams: "",
      totalResults: 100,
      showAllData: false,
      tempTeamItems: this.state.teamsItems,
      tempCrewItems: this.state.crewItems,
      employeeNameItems: this.state.allemployeeNameItems,
      disabledCrew: true,

    });
    localStorage.setItem("ledgerState", "");
    let datagrid = localStorage.getItem("datagridFilterValue");
    if (datagrid) {
      localStorage.removeItem("datagridFilterValue");
    }
  }

  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === "department") {
      if (e.selectedItem !== null) {
        let tempemployeeNameItems = this.state.allemployeeNameItems.filter(
          (f: any) => f.departmentId === e.selectedItem.id
        );
        let tempTeams = this.state.teamsItems;
        let filteredTeam = tempTeams.filter(
          (t: any) => t.departmentId === e.selectedItem.id
        );
        this.setState({
          employeeNameItems: tempemployeeNameItems,
          disableTeam: false,
          tempTeamItems: filteredTeam,
          disableCrew: true,
          tempCrewItems: [],
          teams: "",
          crew: "",
          employeeName: ""
        });
      } else {
        this.setState({
          employeeNameItems: this.state.allemployeeNameItems,
          disableTeam: false,
          tempTeamItems: this.state.teamsItems,
          disabledCrew: true,
          tempCrewItems: [],
          teams: "",
          crew: "",
          employeeName: ""
        });
      }
    }

    if (fieldName === "teams") {
      if (e.selectedItem !== null) {
        let tempCrew = this.state.crewItems;
        const filteredCrew: any[] = tempCrew.filter(
          (c: any) => c.teamId === e.selectedItem.id
        );
        this.setState({
          tempCrewItems: filteredCrew,
          disabledCrew: false,
          crew: ""
        });
      } else {
        this.setState({
          disabledCrew: true,
          tempCrewItems: [],
          crew: ""
        });
      }
    }

    this.setState({ [fieldName]: e.selectedItem }); // Update crew
  }

  private _getEmployeeData() {
    let dataToPassToService: any = {};
    utility
      .genericGetAPICallForList(
        Config.SearchEmployeeApi,
        "POST",
        dataToPassToService
      )
      .then(
        (data: any) => {
          //console.log(data);
          this.employeeNameItems = [];

          this.employeeNameItems = data.filter((r: any) => r.isActive === true && r.departmentCode !== 'DEFAULT').map((r: any) => ({
            id: r.id,
            text: r.fullName,
            departmentId: r.departmentId,
            resourceCode: r.resourceCode,
          }));

          const sortedEmployee = utility._sortItems(this.employeeNameItems, "text", false);
          this.setState({
            employeeNameItems: sortedEmployee,
            allemployeeNameItems: sortedEmployee,
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  private _getDepartmentData() {
    utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then(
      (data: any) => {
        this.departmentItems = [];

        if (data && data.length > 0) {
          this.departmentItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
            id: r.id,
            text: r.title,
            code: r.code,
          }));
        }
        this.departmentItems = utility._sortItems(
          this.departmentItems,
          "text",
          false
        );
        this.setState({
          departmentItems: this.departmentItems,
          alldepartmentItems: this.departmentItems,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private async _getCrewDataAsync() {
    let apiName = `${Config.crewlistname}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let crewItems = [];
      if (data && data.length > 0) {
        crewItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
          id: r.id,
          text: r.title,
          teamId: r.teamId,
          email: r.email,
          departmentId: r.departmentId,
        }));
      }
      const sortedItems = utility._sortItems(crewItems, "text", false);
      this.setState({ crewItems: sortedItems, tempCrewItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }

  private async _getTeamDataAsync() {
    let apiName = `${Config.TeamList}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let teamsItems = [];
      if (data && data.length > 0) {
        teamsItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
          id: r.id,
          text: r.title,
          departmentId: r.departmentId,
        }));
      }
      const sortedItems = utility._sortItems(teamsItems, "text", false);
      this.setState({ teamsItems: sortedItems, tempTeamItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }

  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={"headingDiv"}>
            <span className={"heading"}> Timesheet Export </span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Created From"}
                value={
                  this.state.createdFrom
                    ? new Date(this.state.createdFrom)
                    : null
                }
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "createdFrom")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <DateBoxInput
                stylingMode="underlined"
                label={"Created To"}
                value={
                  this.state.createdTo ? new Date(this.state.createdTo) : null
                }
                type="date"
                displayFormat={"dd/MM/yyyy"}
                placeholder={"DD/MM/YYYY"}
                showClearButton={true}
                onValueChanged={(e: any) =>
                  this.handleValueChange(e, "createdTo")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode="underlined"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label="Employee"
                items={this.state.employeeNameItems}
                selectedItem={this.state.employeeName}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "employeeName")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode="underlined"
                label="Department"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "department")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode="underlined"
                label="Team"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.tempTeamItems}
                selectedItem={this.state.teams}
                disabled={
                  this.state.disableTeam ||
                    this.props.role === global.worxOnline_Contractor ||
                    this.props.role === global.worxOnline_ContractorAdmin
                    ? true
                    : false
                }
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "teams")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode="underlined"
                label="Crew"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.tempCrewItems}
                selectedItem={this.state.crew}
                disabled={
                  this.state.disabledCrew ||
                    this.props.role === global.worxOnline_Contractor ||
                    this.props.role === global.worxOnline_ContractorAdmin
                    ? true
                    : false
                }
                onSelectionChanged={(e: any) =>
                  this.handleDropdownChange(e, "crew")
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv displayFlex"}>
                {this.state.totalRecords && (
                  <div className="totalRecordsDiv">
                    Total records available for this search is{" "}
                    {this.state.totalRecords}
                  </div>
                )}
                <div>
                  <NumberBoxInput
                    stylingMode="underlined"
                    label="Total Results to Display"
                    min={1}
                    max={100000000}
                    value={this.state.totalResults}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "totalResults")
                    }
                  />
                </div>
                <div>
                  <Button
                    onClick={this.clearFilter}
                    variant="outlined"
                    className={"clearButton"}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={this.getWeeklyTimesheet}
                    variant="outlined"
                    color="primary"
                    className={"button"}
                  >
                    Search
                  </Button>
                  <CheckBoxInput
                    value={this.state.showAllData}
                    onValueChanged={(e: any) =>
                      this.handleValueChange(e, "showAllData")
                    }
                    text={"Show All"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          {!this.state.loading ? (
            <div className={"searchFieldDiv"}>
              {this.state.items && this.state.items.length > 0 && (
                <AssignDatatable
                  style={{ width: "100%" }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={this.state.fileName}
                  columnChooser={"TimesheetExportColumns"}
                  stateValue={'TimesheetExportGrid'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  //viewFile={(e: any) => this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              )}
            </div>
          ) : (
            <Spinner
              size="large"
              type={"converging-spinner"}
              className="loader"
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
