import * as React from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import ColorBox from 'devextreme-react/color-box';
import moment from 'moment';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MultiSelectTreeView from '../../common/reusableComponents/MultiSelectTreeView';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
const starttimeLabel = { 'aria-label': 'Work Start Time' };
const finishtimeLabel = { 'aria-label': 'Work Finish Time' };
export default class AddEditCrew extends React.Component<any, any> {
    ClearStorageService = new ClearStorageService();
    public departmentItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateCrew = this.updateCrew.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.state = {
            prestartRequired: false,
            isActive: false,
            useritems: [],
            title: '', code: '', color: '', users: '',
        };
    }
    public didMount() {
        this._getDepartmentData();
        this._getTeamDataAsync();
        this.getUserdata();
        this.populateValue(this.props.details, this.props.mode)
    }

    public populateValue(details?: any, mode?: any) {
        let newdate: Date = new Date();
        let finishTime;
        let startTime;
        startTime = moment(newdate).format("MMM DD YYYY");
        finishTime = moment(newdate).format("MMM DD YYYY");
        if (details?.workStartTime) {
            startTime = startTime + ' ' + details.workStartTime;
        }
        if (details?.workFinishTime) {
            finishTime = finishTime + ' ' + details.workFinishTime;
        }
        this.setState({
            hideAlertButton: false,
            mode: this.props.mode,
            finishTime: finishTime,
            startTime: startTime,
        })
        if (this.props.details && this.props.mode === 'edit') {
        this.setState({
            id: details.id,
            title: details.title,
            code: details.code,
            prestartRequired: this.props.details && details.prestartReq ? this.props.details.prestartReq : false,
            isActive: this.props.details.isActive ? true : false,
            color: details.mapColor,
            created: this.props.details.createdOn,
            createdby: this.props.details.createdBy
        });
    } else {
        this.setState({
            hideAlertButton: false,
            mode: this.props.mode,
            isActive: true,
            prestartRequired: false
        })
    }
    }
    public _getTeamDataAsync() {
        try {
            let filteredItems: any = [];
            utility.genericGetAPICall(`${Config.TeamList}?showAll=true`).then((data: any) => {
                const teamsItems = data.map((r: any) => ({ id: r.id, text: r.title }));
                const sortedItems = utility._sortItems([...teamsItems], 'text', false);

                if (this.props.details && this.props.mode === 'edit') {
                    filteredItems = sortedItems.filter((r: any) => r.id === this.props.details.teamID);
                }
                this.setState({
                    teamsItems: sortedItems,
                    team: filteredItems ? filteredItems : ''
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    public _getDepartmentData() {
        try {
            const data: any = utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`);
            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.id, text: r.title });
            }
            this.setState({ departmentItems: this.departmentItems });
        } catch (err) {
            console.log(err);
        }
    }
    public getUserdata() {
        try {

            utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', {}).then((data: any) => {

                const activeItems = data.filter((r: any) => r.isActive === true).map((r: any) => ({
                    id: r.id,
                    text: r.fullName,
                    crewId: r.crewId,
                    crewName: r.crewName
                }));

                const sortedItems = utility._sortItems(activeItems, 'text', false);

                let filteredItems: any = [];
                let selectedLeadingHand: any = [];
                let leadinghandItems;
                let userItems;

                if (this.props.details && this.props.mode === 'edit') {
                    const crewId = this.props.details.id;

                    userItems = activeItems.filter((r: any) => (r.crewId === crewId || r.crewId === ''));
                    selectedLeadingHand = sortedItems.filter((r: any) => r.id === this.props.details.leadingHandId && r.crewId === crewId);
                }else{
                    //if (selectedLeadingHand.length === 0) {
                        userItems = activeItems.filter((r: any) => (r.crewId === ''));
                    // } else {
                    //     leadinghandItems = filteredItems;
                    // }
                }

                leadinghandItems = (userItems !== undefined && userItems) ? userItems : [];
                const leadinghand = selectedLeadingHand.length > 0
                    ? { id: selectedLeadingHand[0].id, text: selectedLeadingHand[0].text }
                    : '';

                this.setState({
                    leadinghandItems,
                    allleadinghandItems: leadinghandItems,
                    useritems: ((userItems !== undefined && userItems) ? userItems : []),
                    loading: false,
                    leadinghand: selectedLeadingHand ? selectedLeadingHand : ''
                });
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
    }

    public updateCrew() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.code) {
            this.setState({
                alertMessage: 'Please enter  code',
                showAlert: true
            });
            return;
        }
        if (!this.state.team) {
            this.setState({
                alertMessage: 'Please select a team',
                showAlert: true
            });
            return;
        }
        if (!this.state.startTime) {
            this.setState({
                alertMessage: 'Please select Work Start Time',
                showAlert: true
            });
            return;
        }
        if (!this.state.finishTime) {
            this.setState({
                alertMessage: 'Please select Work Finish Time',
                showAlert: true
            });
            return;
        }
        if (!this.state.leadinghand) {
            this.setState({
                alertMessage: 'Please select a Leading Hand',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        let startTime;
        let finishTime;
        if (this.state.finishTime && this.state.startTime) {
            finishTime = new Date(this.state.finishTime);
            startTime = new Date(this.state.startTime);
        }
        dataToPassToService.title = this.state.title;
        dataToPassToService.code = this.state.code;
        dataToPassToService.mapColour = this.state.color;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.teamId = this.state.team.id;
        dataToPassToService.userIds = this.state.users;
        dataToPassToService.isActive = this.state.isActive;
        dataToPassToService.workStartTime = startTime ? startTime.toLocaleTimeString('it-IT') : '';
        dataToPassToService.workFinishTime = finishTime ? finishTime.toLocaleTimeString('it-IT') : '';
        dataToPassToService.leadingHandId = this.state.leadinghand.id;
        dataToPassToService.prestartRequired = this.state.prestartRequired;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.crewlistname, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Crew has been updated");
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.CreateCrewWithUsers, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Crew has been created");
                this.ClearStorageService.clearStorage();
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: false
                });
            });
        }
    }

    public clearFilter() {

        this.setState({ title: '', code: '', color: '', startTime: '', finishTime: '' });
        this.props.onClose();

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {

        this.setState({ [fieldName]: event.value });

    }
    private handletimeValueChange = (event: any, fieldName: string) => {

        this.setState({ [fieldName]: event });
    }

    public handleDropdownChange = (e: any, fieldName: string) => {
        if (e.selectedItem !== null) {
            this.setState({
                [fieldName]: e.selectedItem
            });
        }
    }
    private _multiFieldChanged = (values: any, fieldName: string) => {
        this.setState({[fieldName]: values })
        let filteredusers: any = [];
        if (values.length !== 0) {
            filteredusers = this.state.allleadinghandItems && this.state.allleadinghandItems.filter((o: any) => values.includes(o.id));
            this.setState({ leadinghandItems: filteredusers});
        }

    };


    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                < Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Crew`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Title *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.title}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                            onKeyDown={() => { }}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextBoxInput
                                            stylingMode='underlined'
                                            label="Code *"
                                            // pattern={"[A-Za-z]+"}
                                            // minLength={2}
                                            value={this.state.code}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'code')}
                                            onKeyDown={() => { }}
                                            required={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SelectBox
                                            stylingMode='underlined'
                                            showClearButton={true}
                                            label='Teams *'
                                            valueExpr="id"
                                            displayExpr="text"
                                            dataSource={this.state.teamsItems}
                                            selectedItem={this.state.team}
                                            value={(this.state.team && this.state.team.length > 0) ? this.state.team[0].id : this.state.team}
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'team')}
                                            searchEnabled={true}
                                            searchMode={'contains'}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <MultiSelectTreeView
                                            items={this.state.useritems}
                                            onChange={(e: any) => this._multiFieldChanged(e, 'users')}
                                            selectedItem={this.state.users}
                                            label="Add Users to Crew"
                                            stylingMode='underlined'
                                            displayExpr="text"
                                            valueExpr="id"
                                            showClearButton={true}
                                            placeholder="Select a value..."

                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <SelectBox
                                            stylingMode='underlined'
                                            showClearButton={true}
                                            label='Leading Hand'
                                            valueExpr="id"
                                            displayExpr="text"
                                            dataSource={this.state.leadinghandItems}
                                            selectedItem={this.state.leadinghand}
                                            value={(this.state.leadinghand && this.state.leadinghand.length > 0) ? this.state.leadinghand[0].id : this.state.leadinghand}
                                            onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'leadinghand')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ColorBox
                                            value={this.state.color}
                                            applyValueMode="instantly"
                                            stylingMode='underlined'
                                            showClearButton={true}
                                            label="Color"
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'color')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}><DateBox
                                        inputAttr={starttimeLabel}
                                        value={this.state.startTime}
                                        label={'Work Start Time *'}
                                        showClearButton={true}
                                        onValueChange={(e: any) => this.handletimeValueChange(e, 'startTime')}
                                        type="time" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}><DateBox
                                        inputAttr={finishtimeLabel}
                                        text={this.state.finishTime}
                                        value={this.state.finishTime}
                                        label={'Work Finish Time *'}
                                        showClearButton={true}
                                        onValueChange={(e: any) => this.handletimeValueChange(e, 'finishTime')}
                                        type="time" />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.prestartRequired} onValueChanged={(e: any) => this.handleValueChange(e, 'prestartRequired')} text={'Prestart Required'} />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.updateCrew()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog >

            </div >

        );
    }

}

