import * as React from 'react';
import SchedulerCard from './SchedulerCard';
import global from '../../../classes/global';

export default class MapSchedulerList extends React.Component<any, any> {
    public column: any;
    public list: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentDate: this.props.currentDate,
            currentView: this.props.currentView === 'Calendar' ? 'month' : this.props.currentView === 'agenda' ? 'day' : this.props.currentView,
        };
    }

    public componentDidMount() {

        this.setState({
        })
    }

    public afterInit() {
        this.props.stopOnCrewSelected();
    }
    public render() {
        let isNarrow = false;
        if (window.innerWidth < 984) {
            isNarrow = true;
        }
        if (!isNarrow) {
            return (
                <div className='workorderOnMapList'>
                    {this.props.items && this.props.items.map((element: any, i: any) => {

                        if (element.text !== 'No Work Order Found') {
                            return (
                                this.renderListItem(element, i, this.props, isNarrow)
                            )
                        }
                    })}
                </div>
            );
        } else {
            return (
                <div className='workorderOnMapListNarrow'>
                    {this.props.items && this.props.items.map((element: any, i: any) => {

                        if (element.text !== 'No Work Order Found') {
                            return (
                                this.renderListItem(element, i, this.props, isNarrow)
                            )
                        }
                    })}
                </div>
            );
        }
    }



    public renderListItem(woData: any, i: number, props: any, isNarrow: boolean) {
        let onUnassignedSelectionOption = false;
        let showCrew = false;
        let showUnassignWO = false;
        if (woData.crew && woData.crew > 0) {
            showCrew = true;

        }
        if (woData.fieldstatus && woData.fieldstatus === global.Unassigned) {
            onUnassignedSelectionOption = true
        } else {
            showUnassignWO = true;
        }
        return (
            <div key={i} className={isNarrow ? 'workorderOnMapListCardNarrow' : 'workorderOnMapListCard'}>
                <SchedulerCard
                    woData={woData}
                    // view={'Card'}
                    view={props.view}
                    viewItem={(e: any) => props.viewItem(e)}
                    viewWO={(e: any) => props.viewWO(e)}
                    zoomToSelectWO={(e: any) => props.zoomToSelectWO(e)}
                    mapView={props.mapView}
                    schedulerlistDraggingAllowed={true}
                    dashBoardCard={props.dashBoardCard}
                    _onShowNotesPanel={() => props._onShowNotesPanel(woData)}
                    showUnassignWO={showUnassignWO}
                    onUnassignedSelection={(e: any) => props.onUnassignedSelection(e)}
                    onUnassignedSelectionOption={onUnassignedSelectionOption}
                    showCrew={showCrew}
                    unassignWO={() => props.unassignWO(woData)}
                    isMapListItem={true}
                />
            </div>
        );
    }
}