import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
let _items: any = [];
const StatusListItem: any = [
  { id: 'Fail', text: 'Fail' },
  { id: 'Pass', text: 'Pass' }

];
export default class PrestartChecklist extends React.Component<any, any> {

  public plantitems: any = [];
  public UserItems: any = [];
  public departmentItems: any = [];
  public columns: any;

  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.plantitems = [];
    this.UserItems = [];
    this.departmentItems = [];
    this.getPrestartChecklistwithcriteria = this.getPrestartChecklistwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);



    this.columns = [

      {
        field: 'fileName',
        title: 'File Name',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'file',
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'status',
        title: 'Status',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'driver',
        title: 'Driver',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Department',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crewName',
        title: 'Crew',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'fieldEmployee',
        title: 'Employee',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'equipmentNumber',
        title: 'Plant Number',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'equipmentName',
        title: 'Plant Name',
        // width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'fileURL',
        title: 'File Url',
        width: 160,
        type: 'string',
        visble: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.props.Department : '',
    };
  }


  public componentDidMount() {
    let tempstate = localStorage.getItem('prestartDataState');
    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        plant: allState.plant ? allState.plant : '',
        plantNumber: allState.plantNumber ? allState.plantNumber : '',
        department: allState.department ? allState.department : '',
        status: allState.status ? allState.status : '',
      })
    }
    this._getPlantData();
    this._getUserData();
    this._getDepartmentData();

  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getPrestartChecklistwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: '',
      dateCreatedTo: '',
      dateCreatedfrom: '',
      plant: '',
      status: '',
      user: '',
      plantNumber: '',
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.state.department : ''
    });
    localStorage.setItem('prestartDataState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }
  }
  public viewFile(file: any) {
    let item = file;
    let dataToPassToService: any = {};
    if (!utility.validURL(item.fileURL) || !item.fileName) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.fileName = item.fileName;
    dataToPassToService.fileURL = item.fileURL;
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      //console.log("doc",doc);
      window.open(doc, "_blank");
    });

  }
  private _getPlantData() {
    utility.genericGetAPICallForList(Config.EquipmentsListName).then((data: any) => {
      //console.log(data);
      this.plantitems = [];
      for (let r of data) {
        this.plantitems.push({ id: r.id, text: r.title });
      }
      const sortedItems = utility._sortItems(this.plantitems, 'text', false);
      this.setState({ plantitems: sortedItems, allplantitems: sortedItems });

    }, (err) => {
      console.log(err);
    });
  }
  private _getUserData() {
    utility.genericGetAPICall(`${Config.employeelistname}?showAll=true`).then((data: any) => {
      //console.log(data);
      this.UserItems = [];
      for (let r of data) {
        this.UserItems.push({ id: r.id, text: r.fullName });
      }
      const sortedItems = utility._sortItems(this.UserItems, 'text', false);
      this.setState({ UserItems: sortedItems, allUserItems: sortedItems });

    }, (err) => {
      console.log(err);
    });
  }
  private _getDepartmentData() {
    utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {

      this.departmentItems = [];
      if(data && data.length > 0){
        this.departmentItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id, text: r.title
        }));
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }

  public getPrestartChecklistwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.title) {
      dataToPassToService.title = this.state.title;
      criteriaSelected = true;
    }

    if (this.state.plant) {
      dataToPassToService.equipmentId = this.state.plant.id;
      criteriaSelected = true;
    }
    if (this.state.status) {
      dataToPassToService.status = this.state.status.id;
      criteriaSelected = true;
    }
    if (this.state.user) {
      dataToPassToService.userId = this.state.user.id;
      criteriaSelected = true;
    }
    if (this.state.plantNumber) {
      dataToPassToService.equipmentNumber = this.state.plantNumber;
      criteriaSelected = true;
    }

    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let dfrom;
      let dTo;
      if (this.state.dateCreatedfrom) {
        dfrom = new Date(this.state.dateCreatedfrom).toISOString();
        dataToPassToService.dateFrom = dfrom;
      }
      if (this.state.dateCreatedTo) {
        var extraday = new Date(this.state.dateCreatedTo);
        extraday.setDate(extraday.getDate() + 1);
        dTo = extraday.toISOString().substring(0, 10) + "T13:59:59.000Z";
        // dTo = this.state.dateCreatedTo.toISOString();
        dataToPassToService.dateTo = dTo;
      }

      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    //console.log("dataToPassToService", dataToPassToService);
    this.setState({
      callAfterInit: true,
      loading: true
    });

    utility.genericPostAPIcall(Config.SearchPrestartChecklist, dataToPassToService).then((data: any) => {
      _items = [];
      // console.log(data);
      if (data === null || data.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
        this.setState({ items: _items, loading: false });
        var stateData = { ...this.state };
        localStorage.setItem('prestartDataState', JSON.stringify(stateData));
        return;
      }
      else {
        let items = data.map((item: any) => {
          return {
            id: item.id,
            fileName: item.fileName,
            title: item.title,
            fileURL: item.fileURL,
            driver: item.driver,
            department: item.department,
            status: item.passStatus,
            equipmentName: item.equipmentName,
            equipmentNumber: item.equipmentNumber,
            prestartType: item.prestartType,
            fieldEmployee: item.fieldEmployee,
            crewName: item.crewName,
            createdOn: item.created ? item.created : null
          }
        });
        this.setState({ items: items, loading: false });
        //console.log(_items);
      }

    }, (err: any) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }

  private handleValueChange = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.selectedItem });
  }
  public render(): React.ReactElement<any> {

    return (
      <div >
        {this.state.showAlert && this.alert()}
        < div className={"SearchDiv"} >
          <div className={'headingDiv'}>
            <span className={"heading"}>Prestart Checklist</span>
          </div>
        </div >
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date To'}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='User'
                items={this.state.UserItems}
                selectedItem={this.state.user}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'user')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Plant and Fleet'
                items={this.state.plantitems}
                selectedItem={this.state.plant}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'plant')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <TextBoxInput
                stylingMode='underlined'
                label="Plant and Fleet Number"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.plantNumber}
                onValueChanged={(e: any) => this.handleValueChange(e, 'plantNumber')}
                onKeyDown={this._handleKeyDown}

              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Status'

                items={StatusListItem}
                selectedItem={this.state.status}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'status')}

              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Department'

                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                disabled={(this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}

              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getPrestartChecklistwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>

          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Prestart Checklist'}
                  columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  // showNewModal = {() => this._showModal()}
                  // deleteItem = {(a:any) => this.DeleteDialog(a)}
                  // editItem = {(a:any) => this.EditItemModal(a)}
                  viewFile={(e: any) => this.viewFile(e)}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              )}
            </div>
            :
            // <CircularProgress className="loader" />
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div >
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

