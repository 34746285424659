import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../common/alertDialog';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../common/reusableComponents/Spinner';
import TextAreaInput from '../common/reusableComponents/TextArea';
import TextBoxInput from '../common/reusableComponents/TextBox';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import Divider from '@material-ui/core/Divider';

const DocType: any = [
    { id: 'Photos', text: 'Photos' }
];

export default class AddAssetAttachment extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public details: any = this.props.details;
    public currentUser: any;

    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.uploadedFiles = [];

        this.currentUser = this.props.currentUser;
        this.state = {
            details: this.props.meetings,
        };
    }

    public componentDidMount() {

    }
    public didMount() {

        // this.setState({
        //     Meeting: newMeetingDetails,
        //     reportTitle: this.props.reportTitle,
        //     meetingTitle: this.props.meetingTitle,
        //     meetingId: this.props.meetingId,
        // });
    }
    // public selectReport() {
    //     this.setState({ errMsgUploadFile: "Please choose a file." });
    //     let file = this.fileUpload.files[0];

    //     // if (file.type != "application/document" || file.type != "application/msword") {
    //     //     this.setState({ errMsgUploadFile: "You can only select word document file." });
    //     //     return false;
    //     // }
    //     this.setState({
    //         uploadedFiles: file.name,
    //         errMsgUploadFile: ""
    //     });
    // }


    public uploadAttachment() {

        let file: any = this.uploadedFiles;
        let AssetID: any = this.props.details.Id;

        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {

                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true
                });
                return;
            }
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.docType) {

            this.setState({
                alertMessage: 'Please select document type',
                showAlert: true
            });
            return;
        }

        if (this.uploadedFiles.length === 0) {
            this.setState({
                alertMessage: 'Please attach a document',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner lable="Loading.." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        let d = new Date();
        let amOrPm = (d.getHours() < 12) ? "AM" : "PM";
        let hour = (d.getHours() < 12) ? d.getHours() : d.getHours() - 12;
        let date = d.getDate() + "_" + d.getMonth() + "_" + d.getFullYear() + "_" + hour + "_" + amOrPm;
        for (var i = 0; i < file.length; i++) {
            let photoType = file[i].file.type.replace("image/", ".");
            let data = file[i].file.dataURL.replace("data:" + file[i].file.type + ";base64,", "");

            let dataToPassToService: any = {};
            dataToPassToService.AssetId = +AssetID;
            dataToPassToService.Name = "AssetrPhoto_" + AssetID + "_" + this.props.details.AssetNumber + "_" + date + photoType;
            dataToPassToService.Type = this.state.docType.key;
            // dataToPassToService.LatLng = null;
            dataToPassToService.title = "AssetrPhoto_" + AssetID;
            dataToPassToService.Data = data;
            dataToPassToService.updateBy = this.currentUser.Email;
            utility.genericPostAPIcall(Config.assetDocumentList, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.props.updateDoc();
                this.clearFilter(true);
                this.props.message();
            }, (err: any) => {
                console.log(err);
                this.setState({ showAlert: false });
            });
        }
    }
    public handleEvent(file: any) {

        this.uploadedFiles.push({ file });
        this.setState({ uploadedFile: this.uploadedFiles });
    }

    public clearFilter(item: any) {
        this.setState({ comments: '' });
        this.setState({ fileTitle: '' });
        this.setState({ docType: '' });
        this.props.onClose(item);

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;

            }
        });
        this.uploadedFiles.splice(deletingIndex, 1);
    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private _fieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public _textAreaChanged = (e: any, fieldName: string) => {

        this.setState({ [fieldName]: e.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }

    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            acceptedFiles: 'image/*',
        }
        // var myDropzone;
        const eventHandlers = {
            accept: (file: any, done: any) => {

                if (file.type !== "image/jpeg" && file.type !== "image/png") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },
            init: function (dz: any) {

                //   myDropzone = dz;
            },
            addedfile: (file: any) => {

                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        }
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={'Add Attachment'}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShown={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' >
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.fileTitle}
                                                onValueChanged={(e: any) => this._fieldChanged(e, 'fileTitle')}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>

                                            <TextAreaInput
                                                value={this.state.comment}

                                                label="Comment"
                                                onValueChanged={(e: any) => this._textAreaChanged(e, 'comment')}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>

                                            <SelectBoxInput
                                                stylingMode='underlined'
                                                label='Document Type *'
                                                displayExpr="text"
                                                valueExpr="id"
                                                showClearButton={true}
                                                items={DocType}
                                                selectedItem={this.state.docType}
                                                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'docType')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <DropzoneComponent
                                                eventHandlers={eventHandlers}
                                                djsConfig={djsConfig}
                                                config={componentConfig}
                                                className="dz-message icon ion-upload"
                                                action='Drop files here or click to upload.'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <Divider />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.uploadAttachment,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
