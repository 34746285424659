import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import Autocomplete from 'devextreme-react/autocomplete';
import capitalize from '@material-ui/core/utils/capitalize';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import global from '../../../classes/global';

export default class AddEditMaterials extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.saveData = this.saveData.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
      
        this.state = {
            isActive: false
        }
    }

    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.setState({ loading: true })
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isRequired: false,
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
        this.setState({categoryItems: this.props.categoryItems ? this.props.categoryItems : [],
            measuringUnitItems: this.props.measuringUnitItems ? this.props.measuringUnitItems : []})
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: details.id,
            resourceCode: details.resourceCode,
            title: details.title,
            isActive: details.active ? true : false,
            measuringUnit: details.measuringUnit,
            category: details.category,
            updatedBy: details.updatedBy,
            updatedOn: details.updated,
            createdBy: details.createdBy,
            createdOn: details.created,
            loading: false
        });

    }
    public saveData() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter Title',
                showAlert: true
            });
            return;
        }
        if (!this.state.resourceCode) {
            this.setState({
                alertMessage: 'Please enter Resource Code',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' /> <br /> Loading...</div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.resourceCode = this.state.resourceCode;
        dataToPassToService.title = this.state.title;
        dataToPassToService.measuringUnit = this.state.measuringUnit ? this.state.measuringUnit : '';
        dataToPassToService.category = this.state.category ? this.state.category : '';
        dataToPassToService.isActive = this.state.isActive ? this.state.isActive : false;
        dataToPassToService.tenantId = Config.tenantId;

        if (this.props.mode === 'edit') {
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.materialsListName, this.state.id, dataToPassToService).then((data: any) => {
                this.props.onClick();
                this.props.message("Materials has been updated");
                this.clearFilter();
                this.setState({ showAlert: false, hideAlertButton: false, });
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });

            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.materialsListName, dataToPassToService).then((data: any) => {
                this.props.onClick();
                this.props.message("Materials has been created");
                this.clearFilter();
                this.setState({ hideAlertButton: false, showAlert: false });
            }, (err: any) => {
                // console.log(err);
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({
            resourceCode:'',
            title: '',
            category: '',
            isActive: false,
            measuringUnit: ''
        });
        this.props.onClose();
    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }

    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Dialog
                    open={this.props.showModal}
                    onClose={() => this.clearFilter()}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} Materials`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => this.clearFilter()} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                {!this.state.loading ?
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Resource Code *"
                                                value={this.state.resourceCode}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'resourceCode')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextBoxInput
                                                stylingMode='underlined'
                                                label="Title *"
                                                // pattern={"[A-Za-z]+"}
                                                // minLength={2}
                                                value={this.state.title}
                                                onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                                onKeyDown={() => { }}
                                                required={true}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            stylingMode='underlined'
                                            label='Category'
                                            dataSource={this.state.categoryItems}
                                            value={this.state.category}
                                            showDataBeforeSearch={true}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'category')}
                                        />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            stylingMode='underlined'
                                            label='Unit Of Measure'
                                            dataSource={this.state.measuringUnitItems}
                                            value={this.state.measuringUnit}
                                            showDataBeforeSearch={true}
                                            onValueChanged={(e: any) => this.handleValueChange(e, 'measuringUnit')}
                                        />
                                        </Grid>
                                       
                                        <Grid item xs={12} sm={3}>
                                            <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                        </Grid>
                                    </Grid>
                                    :
                                    <Spinner size="large" label="Loading all component. Please wait..." />
                                }
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => this.clearFilter()} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.saveData()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }

}


