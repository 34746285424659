import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import utility from '../../classes/utility';
import global from '../../classes/global';
import { Config } from '../../classes/config';
import AlertDialog from '../common/alertDialog';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import DateBoxInput from '../common/reusableComponents/DateBox';
import SelectBoxInput from '../common/reusableComponents/SelectBoxUpdated';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import moment from 'moment-timezone';

let _items: any = [];
export default class Searchtimesheet extends React.Component<any, any> {

  public employeeNameItems: any = [];
  public departmentItems: any = [];
  public teamItems: any = [];
  public crewItems: any = [];
  public columns: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.employeeNameItems = [];
    this.departmentItems = [];
    this.teamItems = [];
    this.crewItems = [];
    this.getTimesheetwithcriteria = this.getTimesheetwithcriteria.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);
    this.columns = [

      {
        field: 'Workordernumber',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      {
        field: 'employee',
        title: 'Employee',
        // width: 280,
        type: 'string',
        allowHiding: false,
        groupIndex: 0
      },
      {
        field: 'type',
        title: 'Type',
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'department',
        title: 'Department',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'team',
        title: 'Team',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crew',
        title: 'Crew',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'starttime',
        title: 'Start Time',
        type: 'datetime',
        allowHiding: true,
        cellRender: "cellRender",
        // sortIndex: 0
      },
      {
        field: 'endtime',
        title: 'End Time',
        type: 'datetime',
        cellRender: "cellRender",
        allowHiding: true
      },
      {
        field: 'hoursworked',
        title: 'Hours Worked',
        // width: 160,
        type: 'string',
        cellRender: "cellRender",
        allowHiding: true
      },
      {
        field: '',
        title: '',
        type: 'string',
        allowHiding: true,
        link: 'timesheetLink',
      },
      {
        field: 'isWorkorderOvertime',
        title: 'is Overtime',
        // width: 160,
        type: 'string',
        allowHiding: true
      }
    ];
    this.state = {
      items: _items,
      columns: this.columns,
      callAfterInit: false,
      loading: false,
      department: (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? this.props.Department : '',
      disabledTeam: true,
      disabledCrew: true,
    };
  }


  public async componentDidMount() {
    let tempstate = localStorage.getItem('timesheetState');
    await Promise.all([
      this._getEmployeeData(),
      this._getDepartmentData(),
      this._getTeamDataAsync(),
      this._getCrewDataAsync()
    ]);

    if (tempstate) {
      // this.state = JSON.parse(tempstate)
      let allState = JSON.parse(tempstate);

      this.setState({
        dateCreatedfrom: allState.dateCreatedfrom ? allState.dateCreatedfrom : '',
        dateCreatedTo: allState.dateCreatedTo ? allState.dateCreatedTo : '',
        workorderno: allState.workorderno ? allState.workorderno : '',
        employeename: allState.employeename ? allState.employeename : '',
        crew: allState.crew ? allState.crew : '',
        department: allState.department ? allState.department : '',
        teams: allState.teams ? allState.teams : '',
      })
    }

  }
  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getTimesheetwithcriteria();
    }
  }
  public clearFilter() {
    this.setState({
      items: [],
      workorderno: '',
      employeename: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      crew: '',
      department: '',
      teams: '',
      tempTeamItems: this.state.teamsItems,
      tempCrewItems: this.state.crewItems,
      disabledCrew: true,
    });
    localStorage.setItem('timesheetState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }
  }

  public viewDetials(item: any) {
    this.setState({
      details: item,
      showModal: true
    });
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        columns={this.columns}
        details={this.state.details}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}
      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }


  private _getEmployeeData() {
    let dataToPassToService: any = {};
    utility.genericGetAPICallForList(Config.SearchEmployeeApi, 'POST', dataToPassToService).then((data: any) => {
      //console.log(data);
      this.employeeNameItems = [];

      this.employeeNameItems = data.filter((r: any) => r.isActive === true && r.departmentCode !== 'DEFAULT').map((r: any) => ({
        id: r.id,
        text: r.fullName,
        departmentId: r.departmentId
      }));

      const sortedEmployee = utility._sortItems(this.employeeNameItems, 'text', false);
      this.setState({
        employeeNameItems: sortedEmployee,
        allemployeenameitems: sortedEmployee,
      });

    }, (err) => {
      console.log(err);
    });
  }


  private _getDepartmentData() {
    utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {

      this.departmentItems = [];

      if (data && data.length > 0) {
        this.departmentItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: r.id, text: r.title
          }));
      }
      this.departmentItems = utility._sortItems(this.departmentItems, 'text', false);
      this.setState({ departmentItems: this.departmentItems, alldepartmentItems: this.departmentItems });
    }, (err) => {
      console.log(err);
    });
  }

  private async _getCrewDataAsync() {
    let apiName = `${Config.crewlistname}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let crewItems = [];
      if (data && data.length > 0) {
        crewItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: (r.id), text: (r.title), teamId: r.teamId, email: r.email, departmentId: r.departmentId
          }));
      }
      const sortedItems = utility._sortItems(crewItems, 'text', false);
      this.setState({ crewItems: sortedItems, tempCrewItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }

  private async _getTeamDataAsync() {
    let apiName = `${Config.TeamList}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let teamsItems = [];
      if (data && data.length > 0) {
        teamsItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: r.id, text: r.title, departmentId: r.departmentId,
          }));
      }
      const sortedItems = utility._sortItems(teamsItems, 'text', false);
      this.setState({ teamsItems: sortedItems, tempTeamItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }

  public getTimesheetwithcriteria() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};

    if (this.state.workorderno) {
      dataToPassToService.workorderId = this.state.workorderno;
      criteriaSelected = true;
    }

    if (this.state.employeename) {
      dataToPassToService.employeeResourceCode = this.state.employeename.id;
      criteriaSelected = true;
    }
    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.crew) {
      dataToPassToService.crewId = this.state.crew.id;
      criteriaSelected = true;
    }
    if (this.state.teams) {
      dataToPassToService.teamId = this.state.teams.id;
      criteriaSelected = true;
    }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = moment(this.state.dateCreatedfrom);
      let dcreatedTo = moment(this.state.dateCreatedTo);
      if (dcreatedTo.isBefore(dcreatedfrom, 'day')) {
        this.setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true,
          loading: false
        });
        return;
      }
    }

    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      if (this.state.dateCreatedfrom) {
        const startOfDay = moment(this.state.dateCreatedfrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.dateFrom = startOfDayUTC.toISOString();
      }
      if (this.state.dateCreatedTo) {
        const endOfDay = moment(this.state.dateCreatedTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.dateTo = endOfDayUTC.toISOString();
      }

      criteriaSelected = true;
    }

    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }
    this.setState({
      callAfterInit: true,
      loading: true
    });

    utility.genericPostAPIcall(Config.WOTimeSheetAPI, dataToPassToService).then((data: any) => {
      _items = [];
      //  console.log(data);
      if (data.length === null || data.length <= 0) {

        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });

        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        data.forEach((item: any) => {
          const userTimezone = moment.tz.guess();
          let startTime = null; let endTime = null; let Editedstarttime = null; let Editedendtime = null;

          // Parse and validate startTime
    if (item.startTime) {
      if (moment(item.startTime, moment.ISO_8601, true).isValid()) {
          startTime = moment.utc(item.startTime).tz(userTimezone);
      } else {
          console.warn('Invalid startTime:', item.startTime);
      }
  }

  // Parse and validate endTime
  if (item.endTime) {
      if (moment(item.endTime, moment.ISO_8601, true).isValid()) {
          endTime = moment.utc(item.endTime).tz(userTimezone);
      } else {
          console.warn('Invalid endTime:', item.endTime);
      }
  }

  // Parse and validate editedStartTime
  if (item.editedStartTime) {
      if (moment(item.editedStartTime, moment.ISO_8601, true).isValid()) {
          Editedstarttime = moment.utc(item.editedStartTime).tz(userTimezone);
      } else {
          console.warn('Invalid editedStartTime:', item.editedStartTime);
      }
  }

  // Parse and validate editedFinishTime
  if (item.editedFinishTime) {
      if (moment(item.editedFinishTime, moment.ISO_8601, true).isValid()) {
          Editedendtime = moment.utc(item.editedFinishTime).tz(userTimezone);
      } else {
          console.warn('Invalid editedFinishTime:', item.editedFinishTime);
      }
  }

  // Add parsed values to the output
  _items.push({
      Workordernumber: item.workorderId,
      asseticWONumber: item.erpWorkorderNumber,
      woid: item.workorderId,
      type: item.type,
      employee: item.employeeName,
      department: item.departmentTitle,
      team: item.teamTitle,
      crew: item.crewTitle,
      starttime: startTime ? startTime.format('DD/MM/YYYY h:mm A') : '',
      endtime: endTime ? endTime.format('DD/MM/YYYY h:mm A') : '',
      hoursworked: item.hoursWorked ? item.hoursWorked : '',
      Editedendtime: Editedendtime ? Editedendtime.format('DD/MM/YYYY h:mm A') : '',
      Editedhoursworked: item.editedHoursWorked ? item.editedHoursWorked : '',
      isWorkorderOvertime: item.isWorkorderOvertime ? "Yes" : "No",
  });
});

      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortDateItems(_items, 'starttime', true);

      this.setState({ items: _items, loading: false });
      var stateData = { ...this.state };
      localStorage.setItem('timesheetState', JSON.stringify(stateData));
      //console.log(_items);
    }, (err) => {
      console.log(err);
      this.setState({ loading: false });
    });
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  public handleValueChange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }

  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === "department") {
      if (e.selectedItem !== null) {
        let tempemployeenameItems = this.state.allemployeenameitems.filter((f: any) => f.departmentId === e.selectedItem.id);
        let tempTeams = this.state.teamsItems;
        let filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id);

        this.setState({
          employeeNameItems: tempemployeenameItems,
          disableTeam: false,
          tempTeamItems: filteredTeam,
          disableCrew: true,
          tempCrewItems: [],
          teams: "",
          crew: "",
          employeename: ""
        });
      } else {
        this.setState({
          employeeNameItems: this.state.allemployeenameitems,
          disableTeam: false,
          tempTeamItems: this.state.teamsItems,
          disabledCrew: true,
          tempCrewItems: [],
          teams: "",
          crew: "",
          employeename: ""
        });
      }
    }

    if (fieldName === "teams") {
      if (e.selectedItem !== null) {
        let tempCrew = this.state.crewItems;
        const filteredCrew: any[] = tempCrew.filter(
          (c: any) => c.teamId === e.selectedItem.id
        );
        this.setState({
          tempCrewItems: filteredCrew,
          disabledCrew: false,
          crew: ""
        });
      } else {
        this.setState({
          disabledCrew: true,
          tempCrewItems: [],
          crew: ""
        });
      }
    }


    this.setState({ [fieldName]: e.selectedItem }); // Update crew

  }

  public render(): React.ReactElement<any> {

    return (
      <div>
        {this.state.showAlert && this.alert()}
        {this.state.showModal && this.renderDetailsModal()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}>Timesheet</span>
          </div>
        </div>
        <div className={"searchFieldDiv"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                type="date"
                displayFormat={"dd/MM/yy"}
                label={'Date From'}
                showClearButton={true}
                value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>

              <DateBoxInput
                stylingMode='underlined'
                label={'Date To'}
                type="date"
                displayFormat={"dd/MM/yy"}
                showClearButton={true}
                value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} className={''}>
              <TextBoxInput
                stylingMode='underlined'
                label="Work Order #"
                // pattern={"[A-Za-z]+"}
                // minLength={2}
                value={this.state.workorderno}
                onValueChanged={(e: any) => this.handleValueChange(e, 'workorderno')}
                onKeyDown={this._handleKeyDown}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                label="Department"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.departmentItems}
                selectedItem={this.state.department}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                label="Team"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.tempTeamItems}
                selectedItem={this.state.teams}
                disabled={this.state.disableTeam || (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                label="Crew"
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                items={this.state.tempCrewItems}
                selectedItem={this.state.crew}
                disabled={this.state.disabledCrew || (this.props.role === global.worxOnline_Contractor || this.props.role === global.worxOnline_ContractorAdmin) ? true : false}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <SelectBoxInput
                stylingMode='underlined'
                displayExpr="text"
                valueExpr="id"
                showClearButton={true}
                label='Employee'
                items={this.state.employeeNameItems}
                selectedItem={this.state.employeename}
                onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'employeename')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={"searchButtonDiv"}>
                <Button onClick={this.clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={this.getTimesheetwithcriteria} variant='outlined' color="primary" className={'button'}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>

          {!this.state.loading ?
            <div className='tabStripDiv'>
              {(this.state.items && this.state.items.length > 0) && (
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  rows={this.state.items}
                  columns={this.state.columns}
                  fileName={'Timesheet'}
                  columnChooser={'timesheetColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  viewItem={(a: any) => this.viewDetials(a)}
                  showCommandBtn={false}
                  showViewDetailBtn={true} />

              )}
            </div>
            :
            <Spinner size='large' label={"Loading.."} />
          }
        </div>


      </div>
    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }

}

