import * as React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Divider } from '@material-ui/core';
export default class DisplayDetailsModal extends React.Component<any, any> {

    public state = {
        details: this.props.details,
        showModal: this.props.showModal
    };

    public componentDidMount() {
        ////console.log('component load');

    }

    public afterInit() {
        this.setState({ showModal: this.props.showModal });
    }
    public getValue(item: any) {
        let value: any = '';
        if (this.props.details[item.field]) {
            if (item.type === "datetime" || item.type === "date") {
                let tempValue = this.props.details[item.field]
                value = moment(tempValue).format("DD/MM/YY h:mm a")
            } else {
                value = this.props.details[item.field]
            }
        }
        return value
    }

    public render(): React.ReactElement<any> {
        ////console.log(this.props.details);

        return (
            <div>

                <Popup
                    width={'60vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={'Details'}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    closeOnOutsideClick={false}
                    onVisibleChange={() =>  this.props.onClick()}
                    // onShowing={() => this.afterInit()}

                >
                    <ScrollView width='100%' height='100%' >
                        <div className='popContentDiv'>
                            {
                                (!this.props.details) && (
                                    <div>
                                        <h4 className="alignCenter">No data availabe.</h4>
                                        <br />
                                    </div>
                                )
                            }

                            {(this.props.details && this.props.columns) && (
                                <div>
                                    {this.props.columns && this.props.columns.map((item: any) => {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} sm={3}>{item.title}:</Grid>
                                                <Grid item xs={6} sm={9} className=" ms-fontWeight-semibold">{this.getValue(item)}</Grid>
                                            </Grid>
                                        )
                                    })}


                                </div>
                            )}

                        </div>
                    </ScrollView>
                    <Divider />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: () => this.props.onClick(),
                        }}
                    />
                </Popup>
            </div>
        );
    }
}

