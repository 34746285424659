import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import Grid from '@material-ui/core/Grid';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../common/alertDialog';
import { DropzoneComponent } from 'react-dropzone-component';
import Spinner from '../../common/reusableComponents/Spinner';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import capitalize from '@material-ui/core/utils/capitalize';
import { TagBox } from 'devextreme-react/tag-box';
import DataSource from 'devextreme/data/data_source';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
let myDropzone: any;
export default class AddEditSwms extends React.Component<any, any> {

    public uploadedFiles: any = [];
    public dataURL: any = [];
    public departmentItems: any = [];
    public dataSource: any = [];
    constructor(props: any) {
        super(props);

        this.uploadAttachment = this.uploadAttachment.bind(this);
        this.veiwDocument = this.veiwDocument.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.departmentItems = [];
        this.dataSource = [];
        this.uploadedFiles = [];
        this.dataURL = [];
        this.state = {
            uploadedFile: '',
            department: [],
            isActive: false
        };
    }

    public componentDidMount() {
        //console.log('component load');

    }
    public didMount() {

        this._getDepartmentData();
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode,
            })
        }


    }
    public populateValue(details: any, mode?: any) {
        var ext = details.name;
        ext = ext.split(".").pop();
        this.setState({
            id: details.id,
            fileTitle: details.title,
            name: details.name,
            docNum: details.docNum,
            fileURL: details.fileURL ? this.props.details.fileURL : '',
            data: details.thumbnailData,
            externalUrl: details.fileURL ? this.props.details.fileURL : '',
            department: details.departmentIds,
            createdBy: details.createdBy,
            ext: ext,
            uploadedFile: '',
            isActive: details.isActive ? true : false      });
    }
    private _getDepartmentData() {

        utility.genericGetAPICall(`${Config.DepartmentList}?showAll=true`).then((data: any) => {

            this.departmentItems = [];

            for (let r of data) {
                this.departmentItems.push({ id: r.id, text: r.title });
            }
            const sortedItems = utility._sortItems(this.departmentItems, 'text', false);
            this.dataSource = new DataSource({
                store: sortedItems,
                key: 'id'
            });
            this.setState({
                departmentItems: this.dataSource, alldepartmentItems: this.dataSource
            });
        }, (err) => {
            console.log(err);
        });
    }

    public uploadAttachment() {
        let file = this.uploadedFiles;
        let accpeted;
        if (this.uploadedFiles) {
            this.uploadedFiles.forEach((item: any) => {

                if (item.file.accepted === false) {
                    accpeted = false;
                }
            });
            if (accpeted === false) {
                this.setState({
                    alertMessage: 'Selected file is not accepted. Please select another file.',
                    showAlert: true
                });
                return;
            }
        }
        if (!this.state.fileTitle) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.docNum) {
            this.setState({
                alertMessage: 'Please enter document number',
                showAlert: true
            });
            return;
        }
        if (!this.state.externalUrl) {
            if (this.uploadedFiles.length === 0 && this.props.mode !== 'edit') {
                this.setState({
                    alertMessage: 'Please attach a document',
                    showAlert: true
                });
                return;
            }
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        //let fileType: any;
        let data: any;
        let selecteddepartment: any = [];

        if (this.state.department) {
            this.state.department.forEach((i: any) => {
                selecteddepartment.push(i);
            });
        }

        let dataToPassToService: any = {};
        if (this.state.externalUrl) {
            dataToPassToService.externalUrl = this.state.externalUrl;
            dataToPassToService.fileName = this.state.fileTitle;
        } else {
            if (this.state.uploadedFile) {

                // if (file[0].file.type === "application/msword") {
                //     fileType = ".doc";
                // } else {
                //     fileType = ".docx";
                // }

                data = this.dataURL[0].dataUrl.result.replace("data:" + file[0].file.type + ";base64,", "");
                dataToPassToService.fileName = this.dataURL[0].file;
            }
        }
        dataToPassToService.title = this.state.fileTitle;
        dataToPassToService.isActive = this.state.isActive ? this.state.isActive : false;
        dataToPassToService.documentNumber = this.state.docNum ? this.state.docNum : '';
        dataToPassToService.departmentIds = selecteddepartment;
        dataToPassToService.fileData = data;
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        dataToPassToService.tenantId = Config.tenantId;
        dataToPassToService.fileUrl = this.state.externalUrl ? this.state.externalUrl : '';
        if (this.state.externalUrl) {
            dataToPassToService.isExternalUrl = true;
        }
        if (this.props.mode === 'edit') {
            dataToPassToService.updated = new Date();
            dataToPassToService.id = this.state.id;
            utility.genericUpdateAPICall(Config.swmsDocumentList, this.state.id, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("SWMS has been updated");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.created = new Date();
            dataToPassToService.createdBy = this.props.currentUser.Email;
            utility.genericPostAPIcall(Config.swmsDocumentList, dataToPassToService).then((data: any) => {
                this.setState({ showAlert: false });
                this.props.onClick();
                this.props.message("SWMS has been uploaded");
                this.clearFilter(true);

            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }
    public veiwDocument() {
        let dataToPassToService: any = {};
        if (!utility.validURL(this.props.details.fileURL) || !this.props.details.name) {
            this.setState({
                alertMessage: 'Unable to view this document. \n File URL or File name does exist',
                showAlert: true
            });
            return;
        }
        dataToPassToService.fileName = this.props.details.name;
        dataToPassToService.fileUrl = this.props.details.fileURL;
        utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {

            window.open(doc, "_blank");
        });
    }
    public handleEvent(file: any) {
        this.onDropGeneral(file);
        this.uploadedFiles.push({ file });
        this.setState({ uploadedFile: this.uploadedFiles });
    }

    public onDropGeneral(file: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.dataURL.push({ file: file.name, dataUrl: event ? event.currentTarget : "" });
        };
    }
    public clearFilter(item: any) {
        myDropzone.removeAllFiles(true);
        this.setState({
            docNum: '',
            fileTitle: '',
            externalUrl: '',
            docType: '',
            department: '',
            uploadedFiles: [],
            isActive: false
        });
        this.uploadedFiles = [];
        this.dataURL = [];
        this.props.onClose(item);

    }
    public deleteFile(file: any) {
        var deletingIndex = 0;
        this.uploadedFiles.forEach((element: any, index: any) => {
            if (element.file.name === file.name) {
                deletingIndex = index;
            }
        });

        this.uploadedFiles.splice(deletingIndex, 1);
        this.setState({ uploadedFile: this.uploadedFiles });

    }

    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {
        const componentConfig = {
            showFiletypeIcon: true,
            postUrl: 'no-url'
        };
        const djsConfig = {
            autoProcessQueue: false,
            addRemoveLinks: true,
            maxFiles: 1,
            acceptedFiles: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf',

        };

        const eventHandlers = {
            accept: (file: any, done: any) => {
                if (file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && file.type !== "application/pdf") {
                    done("Error! Files of this type are not accepted");
                }
                else { done(); }
            },
            init: function (dz: any) {

                myDropzone = dz;
            },
            addedfile: (file: any) => {

                this.handleEvent(file);
            },
            removedfile: (file: any) => {
                this.deleteFile(file);
            }

        };
        return (
            <div>
                {this.state.showAlert && this.alert()}

                <Dialog
                    open={this.props.showModal}
                    onClose={this.clearFilter}
                    fullWidth={true}
                    maxWidth={'lg'}
                    aria-labelledby="max-width-dialog-title"
                    onRendered={() => this.didMount()}
                >
                    <DialogTitle id="scroll-dialog-title">
                        {`${this.props.mode && capitalize(this.props.mode)} SWMS`}
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={this.clearFilter} className="closeIcon">
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers={true}>
                        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                            <div className='popContentDiv'>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={3}>
                                            {this.state.name && this.props.mode === 'edit' && <Grid item xs={12} sm={12}>
                                                <span className="detailedListText link" onClick={this.veiwDocument}><strong>{this.state.name}</strong></span>
                                            </Grid>}
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="Title *"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.fileTitle}
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'fileTitle')}
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TagBox
                                                    dataSource={this.state.departmentItems}
                                                    valueExpr="id"
                                                    value={this.state.department ? this.state.department : ''}
                                                    searchEnabled={true}
                                                    displayExpr="text"
                                                    label="Department"
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'department')} />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="Document Number *"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.docNum}
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'docNum')}
                                                    required={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextBoxInput
                                                    stylingMode='underlined'
                                                    label="External URL"
                                                    // pattern={"[A-Za-z]+"}
                                                    // minLength={2}
                                                    value={this.state.externalUrl}
                                                    onValueChanged={(e: any) => this.handleValueChange(e, 'externalUrl')}
                                                    required={true}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                <DropzoneComponent
                                                    eventHandlers={eventHandlers}
                                                    djsConfig={djsConfig}
                                                    config={componentConfig}
                                                    className="dz-message icon ion-upload"
                                                    action='Drop files here or click to upload.'

                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />
                                    </Grid>
                                </Grid>
                            </div>
                        </ScrollView>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={this.clearFilter} variant='contained' color="primary" className={''}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.uploadAttachment()} variant='outlined' color="primary" className={'button'}>
                            Save
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>

        );
    }

}
