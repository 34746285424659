import * as React from 'react';
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AlertDialog from '../common/alertDialog';
import DataTable from '../common/DataTable/DataTable';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import '../../css/Workorder.css';
import moment from 'moment';
import TextBoxInput from '../common/reusableComponents/TextBox';
import Spinner from '../common/reusableComponents/Spinner';
import SelectBoxInput from '../common/reusableComponents/SelectBox';
import DateBoxInput from '../common/reusableComponents/DateBox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberBoxInput from '../common/reusableComponents/NumberBox';
import CheckBoxInput from '../common/reusableComponents/CheckBox';
import AssetMap from '../AssetSearch/AssetMap';
import IndicatorIcon from '../common/indicatorIcon';
import MultiSelectTreeView from '../common/reusableComponents/MultiSelectTreeView';
let _items: any = [];

export default class Searchworkorder extends React.Component<any, any> {

  public criteriaval: any;
  public worktypedataSource: any = [];
  public columns: any;
  public treeView: any;
  public myRef: any;
  public dataGrid: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.criteriaval = '';
    this.treeView = null;
    this.worktypedataSource = [];
    this.myRef = React.createRef();
    this.getWorkOrder = this.getWorkOrder.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.viewDetials = this.viewDetials.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this.statusrenderLoadIndicator = this.statusrenderLoadIndicator.bind(this);
    this.dataGrid = null;
    this.columns = [
      {
        field: 'Workordernumber',
        title: 'worxOnline Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        filteralbe: true,
        link: 'workorderLink',
      },
      {
        field: 'erpWorkorderNumber',
        title: 'Assetic Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        filteralbe: true
      },
      {
        field: 'workrequest',
        title: ' Assetic Req #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        filteralbe: true
      },
      {
        field: 'ExtAssetID',
        title: 'External Asset Id',
        // width: 180,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'assetTitle',
        title: 'Asset Name',
        // width: 160,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'description',
        title: 'Description',
        width: 250,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'worktype',
        title: 'Activity',
        // width: 160,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        filteralbe: true,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'crew',
        title: 'Crew',
        filteralbe: true,
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'leadingHand',
        title: 'Assigned Resource',
        filteralbe: true,
        // width: 160,
        type: 'string',
        allowHiding: true
      },
      {
        field: 'priority',
        title: 'Priority',
        // width: 110,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'fieldstatus',
        title: 'Status',
        // width: 120,
        type: 'string',
        filteralbe: true,
        allowHiding: true
      },
      {
        field: 'workorderLocation',
        title: 'Location',
        // width: 160,
        type: 'string',
        allowHiding: true,
        filteralbe: true,
      },
      {
        field: 'department',
        title: 'Department',
        // width: 160,
        type: 'string',
        filteralbe: true,
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'team',
        title: 'Team',
        // width: 160,
        type: 'string',
        filteralbe: true,
        visibleColumn: false,
        allowHiding: true
      },

      {
        field: 'riskAssessmentRequired',
        title: 'Risk Assessment',
        filteralbe: true,
        // width: 160,
        type: 'boolean',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'photoRequiredBefore',
        title: 'Before Photo',
        filteralbe: true,
        // width: 160,
        type: 'boolean',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'photoRequiredAfter',
        title: 'After Photo',
        filteralbe: true,
        // width: 160,
        type: 'boolean',
        visibleColumn: false,
        allowHiding: true
      },

      {
        field: 'eststartdate',
        title: 'Schedule Start',
        width: 180,
        filteralbe: true,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'requiredByDate',
        title: 'Required Date',
        width: 160,
        filteralbe: true,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'Datecompleted',
        title: 'Completion',
        width: 160,
        filteralbe: true,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        filteralbe: true,
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        filteralbe: true,
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        filteralbe: true,
        visibleColumn: false,
        allowHiding: true
      }

    ];
    this.state = {
      showAllWO: false,
      items: _items,
      columns: this.columns,
      disableTeam: true,
      disableCrew: true,
      callAfterInit: false,
      priority: [],
      loading: false,
      reasonToAttendsItems: [],
      scopesItems: [],
      totalResults: 100,
      expanded: 'panel1',
      disabledTeam: true,
      disabledCrew: true,
      isLoaded: false,
    };
    if (props.cacheLifecycles) {
      props.cacheLifecycles.didCache(this.componentDidCache);
      props.cacheLifecycles.didRecover(this.componentDidRecover);
    }
  }

  public componentDidCache = () => {
    ////console.log('view cached. Scroll position = ' + document.getElementById("s4-workspace").scrollTop);
    //scrollPosition = document.getElementById("s4-workspace").scrollTop;
  }

  public componentDidRecover = () => {
  }


  public async componentDidMount() {
    let search = this.props.location.woProps;
    if (search) {
      this.setState({
        department: search.department,
        crew: search.crew,
        dateCreatedfrom: search.range.startDate,
        dateCreatedTo: search.range.endDate,
      });
      setTimeout(() => this.getWorkOrder(), 500);
    }
    this.setState({ loadingFields: true });
    let tempstate = localStorage.getItem('woState');
    await Promise.all([
      this._getPrioritiesData(),
      this._getStatusDataAsync(),
      this._getworkTypeDataAsync(),
      this._getCrewDataAsync(),
      this._getTeamDataAsync(),
      this._getContractors(),
      this._getDepartmentData()
    ]);

    if (tempstate && !search) {
      let allState = JSON.parse(tempstate);
      this.setState({
        WOnumber: allState.WOnumber ? allState.WOnumber : '',
        StatusItems: allState.StatusItems ? allState.StatusItems : '',
        priorityItems: allState.priorityItems ? allState.priorityItems : '',
        worktypeItems: allState.worktypeItems ? allState.worktypeItems : '',
        wostatus: allState.wostatus ? allState.wostatus : '',
        priority: allState.priority ? allState.priority : '',
        worktype: allState.worktype ? allState.worktype : '',
        WOLocation: allState.WOLocation ? allState.WOLocation : '',
        assetId: allState.assetId ? allState.assetId : '',
        dateCreatedfrom: allState.dateCreatedfrom ? new Date(allState.dateCreatedfrom) : '',
        dateCreatedTo: allState.dateCreatedTo ? new Date(allState.dateCreatedTo) : '',
        reportfrom: allState.reportfrom ? new Date(allState.reportfrom) : '',
        reportTo: allState.reportTo ? new Date(allState.reportTo) : '',
        Completeddatefrom: allState.Completeddatefrom ? new Date(allState.Completeddatefrom) : '',
        CompleteddateTo: allState.CompleteddateTo ? new Date(allState.CompleteddateTo) : '',
        crew: allState.crew ? allState.crew : '',
        department: allState.department ? allState.department : '',
        teams: allState.teams ? allState.teams : '',
        disableTeam: allState.teams ? false : true,
        disableCrew: allState.crew ? false : true,
        workReqNo: allState.workReqNo ? allState.workReqNo : '',
        assetnumber: allState.assetnumber ? allState.assetnumber : '',
        expanded: allState.expanded,
        totalRecords: allState.totalRecords ? allState.totalRecords : '',
        loadingFields: false
      })
    } else {
      this.setState({ loadingFields: false });
    }

  }
  public getMultiselectProps(data: any, listData: any) {
    let returnData: Array<any> = [];
    if (this.state[listData] && data) {
      data.forEach((item: any) => {
        let tempItem = this.state[listData].find((l: any) => l.id === item.id);
        returnData.push(tempItem);
      })
    }
    return returnData;
  }

  public viewDetials(item: any) {

    this.setState({
      details: item,
      showModal: true
    });
  }

  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.getWorkOrder();
    }
  }
  public clearFilter() {
    this.setState({
      items: [],
      WOnumber: '',
      wostatus: [],
      priority: [],
      crew: '',
      department: '',
      teams: '',
      worktype: '',
      WOLocation: '',
      workReqNo: '',
      employee: '',
      dateCreatedfrom: '',
      dateCreatedTo: '',
      actdateCreatedfrom: '',
      actdateCreatedTo: '',
      Completeddatefrom: '',
      CompleteddateTo: '',
      reportfrom: '',
      reportTo: '',
      loading: false,
      totalResults: 100,
      showAllWO: false,
      assetnumber: ''

    });
    localStorage.setItem('woState', '');
    let datagrid = localStorage.getItem('datagridFilterValue');
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue');
    }
  }

  private async _getStatusDataAsync() {
    let apiName = `${Config.statuslist}?showAll=true`;
    this.setState({ isLoaded: false });
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      const filteredItems = data
        .filter((r: any) => r.isActive)
        .map((r: any) => ({ id: r.id, text: r.title }));

      const sortedItems = utility._sortItems(filteredItems, 'text', false);
      this.setState({ StatusItems: sortedItems, isLoaded: true });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getPrioritiesData() {
    try {
      let apiName = `${Config.PriorityListName}?showAll=true`;
      const data: any = await utility.genericGetAPICall(apiName);
      const filteredItems = data
        .filter((r: any) => r.isActive)
        .map((r: any) => ({ id: r.id, text: r.title }));

      const sortedItems = utility._sortItems(filteredItems, 'text', false);
      this.setState({
        priorityItems: sortedItems,
        allpriorityItems: sortedItems
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getworkTypeDataAsync() {
    let apiName = `${Config.WTlistname}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      const worktypeItems = data.map((r: any) => ({
        id: r.id,
        text: r.title,
        selected: true
      }));
      const sortedItems = utility._sortItems(worktypeItems, 'text', false);
      this.setState({
        worktypeItems: sortedItems,
        allworktypeItems: sortedItems
      });
    } catch (err) {
      console.log(err);
    }
  }

  private async _getCrewDataAsync() {
    let apiName = `${Config.crewlistname}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let crewItems = [];
      if (data && data.length > 0) {
        crewItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: (r.id), text: (r.title), teamId: r.teamId, email: r.email, departmentId: r.departmentId
          }));
      }
      const sortedItems = utility._sortItems(crewItems, 'text', false);
      this.setState({ crewItems: sortedItems, tempCrewItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getDepartmentData() {
    let apiName = `${Config.DepartmentList}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let departmentItems = [];
      if (data && data.length > 0) {
        departmentItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: r.id, text: r.title
          }));
      }
      const sortedItems = utility._sortItems(departmentItems, 'text', false);
      this.setState({ departmentItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }

  private async _getTeamDataAsync() {
    let apiName = `${Config.TeamList}?showAll=true`;
    try {
      const data: any = await utility.genericGetAPICall(apiName);
      let teamsItems = [];
      if (data && data.length > 0) {
        teamsItems = data
          .filter((r: any) => r.isActive === true)
          .map((r: any) => ({
            id: r.id, text: r.title, departmentId: r.departmentId,
          }));
      }
      const sortedItems = utility._sortItems(teamsItems, 'text', false);
      this.setState({ teamsItems: sortedItems, tempTeamItems: sortedItems });
    } catch (err) {
      console.log(err);
    }
  }
  public _onDismissMapforDetail() {
    this.setState({
      iFramePanelOpenedforDetail: false
    });
  }

  private SearchClicked() {
    //console.log("mapseacrh clicke");
    this.setState(
      {
        iFramePanelOpened: true,
        selectModeMap: false,
        selectParent: true,
        selectMapDetail: false,
      });

  }
  public renderAssetMap() {
    return (
      <AssetMap
        selectAssetsforParent={(Asset: any) => this.selectAssetsonMapforParent(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClose={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      // selectMapDetail={this.state.selectMapDetail}
      />
    );
  }
  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false
    });
  }
  public selectAssetsonMapforParent(selectedAsset: any) {
    let Asset = selectedAsset[0];
    this.setState({
      parentId: Asset.id,
      parentDetail: Asset.name,
      iFramePanelOpened: false,
      selectModeMap: false,
      selectParent: false,
      selectMapDetail: false,
    });
  }
  private async _getContractors() {
    this.setState({ loading: true });

    try {
      let data: any = await utility.genericGetAPICall(Config.GetContractorsWithDetails);
      let _items = [];

      const mapContractor = (r: any) => ({
        id: r.id,
        text: r.businessName,
        email: r.email,
        teamID: r.teamIds,
        WorkTypes: r.workorderTypes,
        isActive: !!r.isActive,
      });
      _items = data.filter((f: any) => !f.isDeleted).map(mapContractor);

      this.setState({ contractorItems: _items, callAfterInit: true, loading: false, allcontractorItems: _items });
    } catch (err) {
      console.log(err);
    }
  }
  public getWorkOrder() {
    let criteriaSelected = false;

    let dataToPassToService: any = {};
    dataToPassToService.showAll = this.state.showAllWO;

    if (this.state.WOLocation) {
      dataToPassToService.location = this.state.WOLocation;
      criteriaSelected = true;
    }

    if (this.state.workReqNo) {
      dataToPassToService.workRequestId = this.state.workReqNo;
      criteriaSelected = true;
    }
    if (this.state.wostatus && this.state.wostatus.length > 0) {
      let tempwostatus: any = [];
      this.state.wostatus.forEach((element: any) => {
        tempwostatus.push(element);
      });
      dataToPassToService.statusIds = tempwostatus;
      criteriaSelected = true;
    }
    if (this.state.worktype && this.state.worktype.length > 0) {
      let tempworktype: any = [];
      this.state.worktype.forEach((element: any) => {

        tempworktype.push(element);
      });
      dataToPassToService.workTypeIds = tempworktype;
      criteriaSelected = true;
    }

    if (this.state.crew) {
      dataToPassToService.crewId = this.state.crew.id;
      criteriaSelected = true;
    }

    if (this.state.teams) {
      dataToPassToService.teamId = this.state.teams.id;
      criteriaSelected = true;
    }

    if (this.state.department) {
      dataToPassToService.departmentId = this.state.department.id;
      criteriaSelected = true;
    }
    if (this.state.contractor) {
      dataToPassToService.contractorId = this.state.contractor.id;
      criteriaSelected = true;
    }
    if (this.state.WOnumber) {
      dataToPassToService.workorderId = this.state.WOnumber;
      criteriaSelected = true;
    }
    if (this.state.priority && this.state.priority.length > 0) {
      let temppriority: any = [];
      this.state.priority.forEach((element: any) => {
        temppriority.push(element);

      });
      dataToPassToService.priorityIds = temppriority;
      criteriaSelected = true;
    }
    if (this.state.assetnumber) {
      dataToPassToService.assetId = this.state.assetnumber;
      criteriaSelected = true;
    }
    if (this.state.dateCreatedTo && this.state.dateCreatedfrom) {
      let dcreatedfrom = new Date(this.state.dateCreatedfrom);
      let dcreatedTo = new Date(this.state.dateCreatedTo);
      if (dcreatedTo.getTime() < dcreatedfrom.getTime()) {
        this.setState({
          alertMessage: 'Scheduled Start Date To should be greater than Scheduled Start Date From',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.dateCreatedfrom || this.state.dateCreatedTo) {
      let createddatefrom;
      let createdTo;
      if (this.state.dateCreatedfrom) {
        const startOfDay = moment(this.state.dateCreatedfrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.scheduledStartFrom = startOfDayUTC.toISOString();
      }
      if (this.state.dateCreatedTo) {
        const endOfDay = moment(this.state.dateCreatedTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.scheduledStartTo = endOfDayUTC.toISOString();
      }
      criteriaSelected = true;
    }

    if (this.state.Completeddatefrom && this.state.CompleteddateTo) {
      let dcompletedfrom = new Date(this.state.Completeddatefrom);
      let dcompletedTo = new Date(this.state.CompleteddateTo);
      if (dcompletedTo.getTime() < dcompletedfrom.getTime()) {
        this.setState({
          alertMessage: 'Completed Date To should be greater than Completed Date From',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.Completeddatefrom || this.state.CompleteddateTo) {
      let dfrom;
      let dTo;
      if (this.state.Completeddatefrom) {
        const startOfDay = moment(this.state.Completeddatefrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.completedFrom = startOfDayUTC.toISOString();
      }
      if (this.state.CompleteddateTo) {
        const endOfDay = moment(this.state.CompleteddateTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.completedTo = endOfDayUTC.toISOString();
      }
      criteriaSelected = true;
    }

    if (this.state.reportfrom && this.state.reportTo) {
      let reportfrom = new Date(this.state.reportfrom);
      let reportTo = new Date(this.state.reportTo);
      if (reportTo.getTime() < reportfrom.getTime()) {
        this.setState({
          alertMessage: 'Reported Date To should be greater than Reported Date From',
          showAlert: true
        });
        return;
      }
    }
    if (this.state.reportfrom || this.state.reportTo) {
      if (this.state.reportfrom) {
        const startOfDay = moment(this.state.reportfrom).startOf('day');
        const startOfDayUTC = startOfDay.utc();
        dataToPassToService.dateReportedFrom = startOfDayUTC.toISOString();
      }
      if (this.state.reportTo) {
        const endOfDay = moment(this.state.reportTo).endOf('day');
        const endOfDayUTC = endOfDay.utc();
        dataToPassToService.dateReportedTo = endOfDayUTC.toISOString();
      }
      criteriaSelected = true;
    }
    if (!criteriaSelected) {
      this.setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }

    this.setState({
      callAfterInit: true,
      loading: true
    });
    let pageNumber = 1;
    let pageSize = this.state.totalResults ? this.state.totalResults : 200;
    let searchWOAPi = `${Config.WOSearchAPI}?pageNumber=${pageNumber}&pageSize=${pageSize}&showAll=${this.state.showAllWO}`

    utility.genericPostAPIcall(searchWOAPi, dataToPassToService).then((data: any) => {
      _items = []
      if (data.length === 0 || data === null) {

        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });

        this.setState({ items: _items, loading: false });
        return;
      }
      else {
        _items = data.map((item: any) => {
          return {
            id: item.id,
            woid: item.id,
            workrequest: item.workRequestId && item.workRequestId > 1 ? item.workRequestId : '',
            Workordernumber: item.id,
            erpWorkorderNumber: item.erpWorkorderNumber,
            title: item.title,
            description: item.description,
            assetIds: item.assetIds,
            priority: item.priority,
            worktype: item.worktypeName,
            crew: item.crew,
            leadingHand: item.leadingHand,
            team: item.team,
            ExtAssetID: item.erpExtAssetId ? item.erpExtAssetId : item.assetId,
            assetTitle: item.assetTitle ? item.assetTitle : '',
            department: item.department,
            latlong: item.locationLatLong,
            createdBy: item.createdByFullName,
            createdOn: item.created || null,
            Datecompleted: item.dateCompleted || null,
            eststartdate: item.dateScheduledStart || null,
            fieldstatus: item.status,
            suburb: item.suburb ? item.suburb : '',
            photoRequiredAfter: item.photoRequiredAfter,
            photoRequiredBefore: item.photoRequiredBefore,
            riskAssessmentRequired: item.riskAssessmentRequired,
            requiredByDate: item.requiredByDate || null,
            workorderLocation: item.workorderLocation ? item.workorderLocation : ''
          }
        });
      }
      if (_items.length === 1 && !this.props.selectMode) {
        window.open(`#/WorkOrderDetail?WOID=${_items[0].woid}`, '_self')
      }
      if (_items.length <= 0) {
        this.setState({
          alertMessage: 'No results found for this criteria',
          showAlert: true
        });
      }
      _items = utility._sortItems(_items, 'updatedOn', true);
      this.setState({ items: _items, loading: false, totalRecords: data.TotalRecords });
      var stateData = { ...this.state };
      localStorage.setItem('woState', JSON.stringify(stateData));

      //window.scrollTo(0, this.myRef.current.offsetTop)
    }, (err: any) => {
      console.log(err);
      this.setState({
        items: _items,
        loading: false,
        alertMessage: 'Something went wrong!',
        showAlert: true
      });
    });

  }


  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  private handleValueChange(newValue: any, fieldName: string): void {
    this.setState({ [fieldName]: newValue.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {

    if (fieldName === 'department' && e.selectedItem !== null) {
      let tempTeams = this.state.teamsItems;
      let filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id);
      this.setState({
        disableTeam: false,
        tempTeamItems: filteredTeam,
        disableCrew: true,
        tempCrewItems: [],
        teams: '',
        crew: ''
        // [fieldName]: newValue
      });
    }
    if (fieldName === 'teams' && e.selectedItem !== null) {
      let tempCrew = this.state.crewItems;
      let filteredCrew: any = [];
      tempCrew.forEach((c: any) => {
        if (c.teamId === e.selectedItem.id) {
          filteredCrew.push(c);
        }
      });
      this.setState({
        disableCrew: false,
        tempCrewItems: filteredCrew,
        disabledCrew: false
      });
    }
    this.setState({ [fieldName]: e.selectedItem });
  }
  private _multiFieldChanged = (event: any, fieldName: string) => {
    this.setState({ [fieldName]: event });
  }
  public handleAccordionChange(item: any) {
    this.setState({ expanded: item });
  }
  statusrenderLoadIndicator() {
    return <IndicatorIcon isLoaded={this.state.isLoaded} />;
  }
  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        <div >
          {this.renderAssetMap()}
          {this.state.details && this.renderDetailsModal()}
          {this.state.showAlert && this.alert()}
          <div className={"SearchDiv"}>
            <div className={'headingDiv'}>
              <span className={"heading"}> Search Work Orders </span>
            </div>
          </div>
          {!this.state.loadingFields ?
            <>
              <div className={"searchFieldDiv"}>

                <Accordion
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={'classes.heading'}></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                          stylingMode='underlined'
                          label="Work Order #"
                          value={this.state.WOnumber}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'WOnumber')}
                          onKeyDown={(e: any) => this._handleKeyDown(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                          stylingMode='underlined'
                          label="Work Request #"
                          value={this.state.workReqNo}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'workReqNo')}
                          onKeyDown={(e: any) => this._handleKeyDown(e)}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                          stylingMode='underlined'
                          label="Work Order Location"
                          value={this.state.WOLocation}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'WOLocation')}
                          onKeyDown={(e: any) => this._handleKeyDown(e)}
                        />
                      </Grid>
                      {this.state.worktypeItems &&
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <MultiSelectTreeView
                            items={this.state.worktypeItems}
                            onChange={(e: any) => this._multiFieldChanged(e, 'worktype')}
                            selectedItem={this.state.worktype}
                            label="Activity"
                            stylingMode='underlined'
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            placeholder="Select a value..."
                          />
                        </Grid>
                      }
                      {this.state.priorityItems &&
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                          <MultiSelectTreeView
                            items={this.state.priorityItems}
                            onChange={(e: any) => this._multiFieldChanged(e, 'priority')}
                            selectedItem={this.state.priority}
                            label="Priority"
                            stylingMode='underlined'
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            placeholder="Select a value..."
                          />
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <MultiSelectTreeView
                          items={this.state.StatusItems}
                          onChange={(e: any) => this._multiFieldChanged(e, 'wostatus')}
                          selectedItem={this.state.wostatus}
                          label="Status"
                          stylingMode='underlined'
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          placeholder="Select a value..."
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                          stylingMode='underlined'
                          label="Asset #"
                          value={this.state.assetnumber}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'assetnumber')}
                          onKeyDown={(e: any) => this._handleKeyDown(e)}

                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={'classes.heading'}>Search by Dates</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>

                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Schedule Date From'}
                          value={this.state.dateCreatedfrom ? new Date(this.state.dateCreatedfrom) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedfrom')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Reported Date From'}
                          value={this.state.reportfrom ? new Date(this.state.reportfrom) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'reportfrom')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Completed Date From'}
                          value={this.state.Completeddatefrom ? new Date(this.state.Completeddatefrom) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'Completeddatefrom')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Scheduled Date To'}
                          value={this.state.dateCreatedTo ? new Date(this.state.dateCreatedTo) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'dateCreatedTo')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Reported Date To'}
                          value={this.state.reportTo ? new Date(this.state.reportTo) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'reportTo')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <DateBoxInput
                          stylingMode='underlined'
                          label={'Completed Date To'}
                          value={this.state.CompleteddateTo ? new Date(this.state.CompleteddateTo) : null}
                          type="date"
                          displayFormat={"dd/MM/yyyy"}
                          placeholder={"DD/MM/YYYY"}
                          showClearButton={true}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'CompleteddateTo')}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={'classes.heading'}> Advanced Search</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <SelectBoxInput
                          stylingMode='underlined'
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.departmentItems}
                          selectedItem={this.state.department}

                          label='Department'
                          onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'department')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <SelectBoxInput
                          stylingMode='underlined'
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.tempTeamItems}
                          selectedItem={this.state.teams}
                          disabled={this.state.disableTeam ? true : false}
                          label='Teams'
                          onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'teams')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <SelectBoxInput
                          stylingMode='underlined'
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.tempCrewItems}
                          selectedItem={this.state.crew}
                          disabled={this.state.disabledCrew ? true : false}
                          label='Crew '
                          onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'crew')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <SelectBoxInput
                          stylingMode='underlined'
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.contractorItems}
                          selectedItem={this.state.contractor}
                          label='Contractor'
                          onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'contractor')}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <div className={"searchButtonDiv displayFlex"}>
                      {this.state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {this.state.totalRecords}</div>}
                      <div>
                        <NumberBoxInput
                          stylingMode='underlined'
                          label="Total Results to Display"
                          min={1}
                          max={100000000}
                          value={this.state.totalResults}
                          onValueChanged={(e: any) => this.handleValueChange(e, 'totalResults')}
                        />
                      </div>
                      <div>
                        <Button onClick={this.clearFilter} variant='outlined' className={'clearButton'}>
                          Clear
                        </Button>
                        <Button onClick={this.getWorkOrder} variant='outlined' color="primary" className={'button'}>
                          Search
                        </Button>

                        <CheckBoxInput value={this.state.showAllWO} onValueChanged={(e: any) => this.handleValueChange(e, 'showAllWO')} text={'Show All Work Orders'} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>

                {!this.state.loading ?
                  <div className={"searchFieldDiv"}>
                    {(this.state.items && this.state.items.length > 0) && (
                      <DataTable
                        style={{ width: '100%' }}
                        filteralbe={true}
                        groupable={true}
                        rows={this.state.items}
                        columns={this.state.columns}
                        fileName={'workorders'}
                        columnChooser={'workorderColumns'}
                        selectionMode={false}
                        showHideColumnMenu={true}
                        callAfterInit={this.state.callAfterInit}
                        stopCommsAfterInit={() => this.stopCommsAfterInit()}
                        viewItem={(a: any) => this.viewDetials(a)}
                        loading={this.state.loading}
                        allowExportSelecteditems={false}
                        showNewBtn={false}
                        showCommandBtn={false}
                        showViewDetailBtn={true}
                      />
                    )}
                  </div>

                  :
                  // <CircularProgress className="loader" />
                  <Spinner size='large' label="Loading.." className="loader" />
                }
              </div>
            </> :
            <Spinner label="Loading all fields. Please wait" size="large" />
          }

        </div>
      </React.Fragment >

    );
  }

  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public renderDetailsModal() {
    return (
      <DisplayDetailsModal
        details={this.state.details}
        columns={this.columns}
        showModal={this.state.showModal}
        onClick={() => this._closeModal()}

      />
    );
  }
  private _closeModal = (): void => {
    this.setState({ showModal: false });
  }

}